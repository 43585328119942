<template>
  <div>
    <div v-if="visiblePage === 'Compose'">
      <MessageComposer
        message="*Please note:* Max characters for SMS are 100 characters."
        :finalizeMessage="finalizeMessage"
        :showSendTestEmail="false"
        :showPreview="true"
        :processPreview="processFinalPreview"
        :showSms="true"
        :composedText="smsMessage"
        :composedSubject="smsSubject"
        :isReadOnly="isReadOnly"
      />
    </div>
    <div v-else-if="visiblePage === 'FinalPreview'">
      <div class="force-center subtitle pb-5">
        File Preview
      </div>
      <div class="columns">
        <div class="column is-4">&nbsp;</div>
        <div class="column is-4">
          <img :src="require('@/assets/images/transparent_cell.png')" />
          <div class="font-centered">
            <div
              class="font-bold"
              v-if="typeof (composedSubject === 'string')"
              v-html="composedSubject"
            ></div>
            <div v-if="typeof (composedText === 'string')" v-html="composedText"></div>
          </div>
        </div>

        <div class="column is-4">&nbsp;</div>
      </div>
      <div>
        <div>
          <b-button type="is-text" @click.prevent="processCompose">Edit Message</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageComposer from '../keys/MessageComposer'
import { mapState, mapGetters } from 'vuex'
import { notifyMessage, notifyError } from '@/services/notify'
import { mailingCommunicationStore } from '@/services/MailingCommunications/store'

export default {
  name: 'SmsMessage',

  props: {
    communication: Object,
    isReadOnly: Boolean,
    step: Number
  },

  components: {
    MessageComposer
  },

  data() {
    return {
      visiblePage: 'Compose',
      composedText: '',
      composedSubject: ''
    }
  },

  methods: {
    processSendTestEmail() {},

    processFinalPreview(content, subject) {
      console.debug('in processFinalPreview...' + content)

      let tempContent = []
      let processedContent = content
      const contentLength = content.length > 17 ? Math.round(content.length / 17) : 0

      console.debug('contentLength=' + contentLength)

      if (contentLength > 0 && content.indexOf('\n') <= -1) {
        processedContent = ''
        for (let i = 0; i < contentLength; i++) {
          let startIndex = processedContent !== undefined ? processedContent.length - i : 0
          let endIndex = 17 * (i + 1)

          tempContent[i] = content.substring(startIndex, endIndex)

          processedContent = processedContent + tempContent[i] + '\n'

          console.debug(
            i +
              '=, processedContent=' +
              processedContent +
              'startIndex=' +
              startIndex +
              'endIndex=' +
              endIndex
          )
        }
      }

      let tempSubject = []
      let processedSubject = subject
      const contentLength2 = subject.length > 17 ? Math.round(subject.length / 17) : 0

      console.debug('contentLength2=' + contentLength2)

      if (contentLength2 > 0 && subject.indexOf('\n') <= -1) {
        processedSubject = ''
        for (let i = 0; i < contentLength2; i++) {
          let startIndex = processedSubject !== undefined ? processedSubject.length - i : 0
          let endIndex = 17 * (i + 1)

          tempSubject[i] = subject.substring(startIndex, endIndex)

          processedSubject = processedSubject + tempSubject[i] + '\n'

          console.debug(
            i +
              '=, processedContent=' +
              processedSubject +
              'startIndex=' +
              startIndex +
              'endIndex=' +
              endIndex
          )
        }
      }

      this.$store.dispatch('communication/composedSmsMessage', {
        smsMessage: content
      })

      this.$store.dispatch('communication/saveSmsSubject', {
        smsSubject: subject
      })

      this.composedText = processedContent
      this.composedSubject = processedSubject
      this.visiblePage = 'FinalPreview'
    },

    processCompose() {
      this.visiblePage = 'Compose'
    },

    async finalizeMessage(content, subject) {
      console.debug(content + ' finalizeMessage sms...' + subject)

      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      if (subject === undefined || !subject) {
        notifyError('The subject is required.')
        loadingComponent.close()
        return
      } else if (subject.length > 25) {
        notifyError('The subject cannot be greater than 25 characters.')
        loadingComponent.close()
        return
      }

      if (content === undefined || !content) {
        notifyError('The sms message is required.')
        loadingComponent.close()
        return
      } else if (content.length > 100) {
        notifyError('The message cannot be greater than 100 characters.')
        loadingComponent.close()
        return
      }

      if (content && content.length > 0) {
        this.$store.dispatch('communication/composedSmsMessage', {
          smsMessage: content
        })
        this.composedText = content

        this.$store.dispatch('communication/saveSmsSubject', {
          smsSubject: subject
        })
        this.composedSubject = subject

        const params = {
          communicationID: this.mailingCommunicationID,
          supportMailDelivery: this.supportMailDelivery,
          supportEmailDelivery: this.supportEmailDelivery,
          supportSMSDelivery: this.supportSMSDelivery,
          supportPushDelivery: this.supportPushDelivery,
          isLegalCommunication: this.isLegalCommunication,
          description: this.communicationDescription,
          mailFullDuplex: null,
          mailColor: null,
          htmlSmsContent: content,
          smsSubject: subject
        }

        await mailingCommunicationStore.dispatch('updateCommunicationMessage', {
          params,
          done: async ({ detail }) => {
            if (detail) {
              console.debug('update communication=' + JSON.stringify(detail))

              this.communicationID = detail.communicationID

              notifyMessage('This message has been saved for sending.')
            }
          }
        })
      }

      loadingComponent.close()
    },

    loadFromState() {
      setTimeout(() => {
        this.composedText = this.smsMessage || ''
        this.composedSubject = this.smsSubject || ''
        console.debug('loadFromState...' + this.composedText + ', subject=' + this.composedSubject)
      }, 4000)
    }
  },

  mounted() {
    this.composedText = this.smsMessage
    this.composedSubject = this.smsSubject
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('communication', [
        'supportMailDelivery',
        'supportEmailDelivery',
        'supportSMSDelivery',
        'isLegalCommunication',
        'mailingTypeID',
        'smsMessage',
        'smsSubject',
        'mailingCommunicationID',
        'communicationDescription',
        'isColor',
        'isFullDuplex'
      ])
    })
  }
}
</script>
