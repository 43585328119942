<template>
  <div>
    <div class="pb-4 columns">
      <span
        class="pr-1 column is-left force-left is-3"
        v-if="showPreview !== undefined && showPreview === true"
        ><b-button
          type="is-primary"
          @click.prevent="processPreview(content, subject, isDuplex, isColor)"
          >Show Preview</b-button
        ></span
      >
      <span
        class="pr-5 column is-left force-left"
        v-if="showSendTestEmail !== undefined && showSendTestEmail === true"
        ><b-button
          type="is-primary"
          @click.prevent="processSendTestEmail(content, subject)"
          :disabled="isReadOnly === true"
          >Send Test Email</b-button
        ></span
      >
      <span class="pr-5 column is-right" style="text-align: right !important;"
        ><b-button
          type="is-primary"
          @click="finalizeMessage(content, subject, isDuplex, isColor)"
          :disabled="isReadOnly === true"
          >Finalize Message</b-button
        ></span
      >
    </div>
    <div
      class="media-content columns"
      v-if="showMailOptions === true"
      style="overflow: hidden !important;"
    >
      <div class="column is-1 force-left">
        <b-checkbox
          v-model="isDuplex"
          :native-value="isDuplex"
          :disabled="isReadOnly === true"
        ></b-checkbox>
      </div>
      <div class="column is-2 force-left">
        Full Duplex
      </div>
      <div class="column is-1 force-left">
        <b-checkbox
          v-model="isColor"
          :native-value="isColor"
          :disabled="isReadOnly === true"
        ></b-checkbox>
      </div>
      <div class="column is-2 force-left">
        Color
      </div>
    </div>
    <div class="pb-3" v-if="showSendTestEmail !== undefined && showSendTestEmail === true">
      <div class="columns">
        <span class="column is-3 text-right"
          >Subject Line<br /><span class="small-text is-right">Max 100 characters</span></span
        ><span class="column is-9"
          ><valid-input
            style="width:50px !important;"
            name="EmailSubject"
            type="text"
            vid="EmailSubject"
            id="EmailSubject"
            placeholder="Email Subject"
            spellcheck="true"
            aria-label="Email Subject"
            rules="max:100"
            maxlength="100"
            class="is-small"
            v-model="subject"
            :disabled="isReadOnly === true"
        /></span>
      </div>
    </div>
    <vue-editor
      id="editor"
      v-if="!showSms"
      v-model="content"
      maxlength="8000"
      useCustomImageHandler
      @image-added="handleImageAdded"
      @image-removed="handleImageRemoved"
      :editor-toolbar="customToolbar"
      :disabled="isReadOnly === true"
    />
    <div v-if="showSms !== undefined && showSms && showSms === true">
      <div class="columns pb-3">
        <span class="column is-2 text-right"
          >Subject Line<br /><span class="small-text is-right">Max 25 characters</span></span
        ><span class="column is-10"
          ><valid-input
            style="width:50px !important;"
            name="SmsSubject"
            type="text"
            vid="SmsSubject"
            id="SmsSubject"
            placeholder="Sms Subject"
            spellcheck="true"
            aria-label="Sms Subject"
            rules="max:25"
            maxlength="25"
            class="is-small"
            v-model="subject"
            :disabled="isReadOnly === true"
        /></span>
      </div>
      <div class="columns">
        <div class="column is-12">
          <valid-input
            style="width:50px !important;"
            name="SmsMessage"
            type="textarea"
            vid="SmsMessage"
            placeholder="Sms Message"
            aria-label="Sms Message"
            rules="required|max:100"
            class="is-small"
            maxlength="100"
            v-model="content"
            :disabled="isReadOnly === true"
          />
        </div>
      </div>
    </div>
    <div>
      {{ message }}
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor/dist/vue2-editor.core.js'
import ValidInput from '@/components/inputs/ValidInput'

export default {
  name: 'MessageComposer',
  props: {
    message: String,
    composedText: String,
    composedSubject: String,
    composedIsFullDuplex: Boolean,
    composedIsColor: Boolean,
    showMailOptions: Boolean,
    showPreview: Boolean,
    showSendTestEmail: Boolean,
    showSms: Boolean,
    finalizeMessage: Function,
    processSendTestEmail: Function,
    processPreview: Function,
    handleImageAdded: Function,
    handleImageRemoved: Function,
    isReadOnly: Boolean
  },
  components: {
    VueEditor,
    ValidInput
  },
  data() {
    return {
      content: '',
      subject: '',
      isDuplex: false,
      isColor: false,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link'],
        ['clean'],
        ['image']
      ]
    }
  },
  methods: {},
  mounted() {
    this.content = this.composedText
    this.subject = this.composedSubject
    this.isColor = this.composedIsColor
    this.isDuplex = this.composedIsFullDuplex
  }
}
</script>
<style lang="css">
@import '~vue2-editor/dist/vue2-editor.css';

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>
