<template>
  <div inspection-close-modal id="inscpectionCompleteModal" class="section">
    <div class="container">
      <div class="is-size-4">Complete Inspection</div>
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="pt-5 pb-6">
                  <div class="column is-6">
                    <label class="label">Inspection Complete Date</label>
                    <DatePicker
                      v-model="inspectionCompleteDate"
                      ariaLabel="Inspection Completion Date"
                      ref="inspectionCompleteDateComponent"
                    />
                  </div>
                </div>
                <div class="pt-6"></div>
                <div class="pt-6"></div>
                <div class="pt-6 pl-2">
                  <button
                    type="submit"
                    class="button is-primary is-rounded"
                    tests-id="update-work-order-button"
                  >
                    Complete Inspection
                  </button>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import DatePicker from '@/components/inputs/DatePicker'

export default {
  name: 'InspectionCompleteModal',
  props: {
    escrowID: Number
  },

  components: {
    DatePicker,
    ValidationObserver,
    ValidationProvider
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
<style lang="scss"></style>
