<template>
  <PageContent>
    <div class="level pl-2">
      <div class="level-left level-item title">{{ $t('escrowList.title') }}</div>
      <div
        v-if="statusFilter === 'pending'"
        class="level-left level-item pb-3"
        :style="{ maxWidth: '80%' }"
      ></div>
      <div class="level-right">
        <b-field tabindex="0" aria-label="escrow status filter">
          <b-radio
            v-model="statusFilter"
            name="pending"
            native-value="pending"
            aria-label="Pending"
          >
            Pending
          </b-radio>
          <b-radio
            v-model="statusFilter"
            name="complete"
            native-value="complete"
            aria-label="Complete"
          >
            Complete
          </b-radio>
          <b-radio
            v-model="statusFilter"
            name="inspection"
            native-value="inspection"
            aria-label="Pending Inspection"
          >
            Pending Inspection
          </b-radio>
        </b-field>
      </div>
    </div>
    <div class="pb-3">
      <b-notification
        v-if="escrowPropertiesList && escrowPropertiesList.length >= 12"
        type="is-info is-light"
        has-icon
        aria-close-label="Close notification"
      >
        <div class="pb-3">
          For new escrow information requests, please instruct the Title Company to send the request
          to
          <span class="font-bold"
            ><a class="href-overflow-wrap" href="mailto:${this.escrowExtended}">{{
              escrowExtended
            }}</a></span
          >
          with the following information included:
          <div>&nbsp;</div>
          <div class="columns">
            <div class="column">{{ escrowPropertiesList[0] }}</div>
            <div class="column">{{ escrowPropertiesList[1] }}</div>
            <div class="column">{{ escrowPropertiesList[2] }}</div>
          </div>
          <div class="columns">
            <div class="column">{{ escrowPropertiesList[3] }}</div>
            <div class="column">{{ escrowPropertiesList[4] }}</div>
            <div class="column">{{ escrowPropertiesList[5] }}</div>
          </div>
          <div class="columns">
            <div class="column">{{ escrowPropertiesList[6] }}</div>
            <div class="column">{{ escrowPropertiesList[7] }}</div>
            <div class="column">{{ escrowPropertiesList[8] }}</div>
          </div>
          <div class="columns">
            <div class="column">{{ escrowPropertiesList[9] }}</div>
            <div class="column">{{ escrowPropertiesList[10] }}</div>
            <div class="column">{{ escrowPropertiesList[11] }}</div>
          </div>
          <div class="columns">
            <div class="column">{{ escrowPropertiesList[12] }}</div>
            <div class="column">&nbsp;</div>
            <div class="column">&nbsp;</div>
          </div>
        </div>
      </b-notification>
    </div>
    <div>
      <ModernTable
        :region="region"
        :filters="filters"
        :rows="rows"
        :columns="columns"
        :pagination="{
          perPage: 12
        }"
        :loading="loading"
      />
    </div>
    <Modal :toggle.sync="toggle">
      <InspectionCompleteModal :escrowID="parseInt(selectedEscrowID)"> </InspectionCompleteModal>
    </Modal>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import ModernTable from '@/components/tables/Modern/Table'
import InspectionCompleteModal from './components/inspectionCompleteModal'
import Modal from '@/components/Modal'
import { mapState, mapGetters } from 'vuex'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'EscrowList',
  components: {
    PageContent,
    ModernTable,
    InspectionCompleteModal,
    Modal
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,

  methods,

  async mounted() {
    await this.reload()
    this.hrefMailto = `mailto:${this.escrowExtended}`
  },

  watch: {
    statusFilter: 'reload',
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  i18n: {
    messages: {
      en: {
        escrowList: {
          title: 'Escrow'
        }
      }
    }
  }
}
</script>

<style></style>
