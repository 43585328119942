import moment from 'moment'

var field = {
  width: '100px',
  display: 'inline-block',
  paddingRight: '6px',
  textAlign: 'left'
}

export const data = () => ({
  formComplete: false,
  formData: {},
  loading: true,
  isDebug: true,
  openOnFocus: true,
  isFetching: false,
  viewOnly: true,
  escrowID: 0,
  escrowDetail: {},
  escrowBuyerName: '',
  formatDateFn: function(value) {
    return value && value != undefined
      ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
          .format('MM-DD-YYYY')
          .toString()
      : ''
  },
  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    },
    amountField: Object.assign({}, field, {
      width: '125px'
    })
  }
})
