<template>
  <div communication-modal>
    <div class="level pl-1 pr-1">
      <div v-if="!isReadOnly" class="pt-5 level-left level-item title">
        {{
          `${communication !== undefined && communication ? 'Edit ' : 'Add '}` +
            $t('mailingCommunications.title')
        }}
      </div>
      <div v-else class="pt-5 level-left level-item title">
        {{ 'View ' + $t('mailingCommunications.title') }}
      </div>
    </div>
    <div>
      <b-steps v-model="activeStep" size="is-small" :has-navigation="true">
        <b-step-item label="Delivery Methods">
          <DeliveryMethods
            :communication="communication"
            :isReadOnly="isReadOnly"
            :step="activeStep"
          />
        </b-step-item>
        <b-step-item label="Scheduling">
          <Scheduling :communication="communication" :isReadOnly="isReadOnly" :step="activeStep" />
        </b-step-item>
        <b-step-item label="Compose Message">
          <div class="subtitle force-center pt-5">Compose Your Message</div>
          <div class="force-center mb-5">
            Please compose your message for all of the selected delivery methods before proceeding.
          </div>
          <b-tabs
            position="is-left"
            type="is-boxed"
            class="block whitecolor"
            ref="composeMessageTabs"
          >
            <b-tab-item v-if="supportMailDelivery === true" label="US Postal Mail">
              <ComposeMessage
                :communication="communication"
                :isReadOnly="isReadOnly"
                :step="activeStep"
              />
            </b-tab-item>
            <b-tab-item v-if="supportEmailDelivery === true" label="Email"
              ><EmailMessage
                :communication="communication"
                :isReadOnly="isReadOnly"
                :step="activeStep"
            /></b-tab-item>
            <b-tab-item v-if="supportSMSDelivery === true" label="SMS/Push"
              ><SmsMessage
                :communication="communication"
                :isReadOnly="isReadOnly"
                :step="activeStep"
            /></b-tab-item>
          </b-tabs>
        </b-step-item>
        <b-step-item label="Target Audiences">
          <TargetAudience
            :showPreview="false"
            :communication="communication"
            :isReadOnly="isReadOnly"
            :step="activeStep"
          />
        </b-step-item>
        <b-step-item label="Review Summary"
          ><Review
            :step.sync="activeStep"
            :isReadOnly.sync="isReadOnly"
            :closeCommunication="closeCommunication"
        /></b-step-item>
      </b-steps>
    </div>
    <div class="mt-6"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ComposeMessage from './keys/ComposeMessage'
import EmailMessage from './keys/EmailMessage'
import SmsMessage from './keys/SmsMessage'
import TargetAudience from './keys/TargetAudience'
import Review from './keys/Review'
import DeliveryMethods from './keys/DeliveryMethods'
import Scheduling from './keys/Scheduling'

export default {
  props: {
    communication: Object,
    isReadOnly: Boolean
  },

  components: {
    ComposeMessage,
    EmailMessage,
    SmsMessage,
    TargetAudience,
    Review,
    DeliveryMethods,
    Scheduling
  },

  mounted() {
    this.$store.dispatch('communication/initStates').then(() => {
      this.reload()

      if (this.communication !== undefined && this.communication) {
        //Set state id
        this.$store.dispatch('communication/saveSessionCommunicationID', {
          mailingCommunicationID: this.communication.communicationID
        })
        this.$store.dispatch('communication/saveMailingTypeID', {
          mailingTypeID: this.communication.mailingTypeID
        })

        //Populate Delivery Methods
        this.$store.dispatch('communication/saveDescription', {
          communicationDescription: this.communication.description
        })

        this.$store.dispatch('communication/saveSupportMailDelivery', {
          supportMailDelivery: this.communication.supportMailDelivery
        })

        if (this.communication.isLegalCommunication === false) {
          this.$store.dispatch('communication/saveSupportEmailDelivery', {
            supportEmailDelivery: this.communication.supportEmailDelivery
          })
          this.$store.dispatch('communication/saveSupportSMSDelivery', {
            supportSMSDelivery: this.communication.supportSMSDelivery
          })
        } else {
          this.$store.dispatch('communication/saveSupportEmailDelivery', {
            supportEmailDelivery: false
          })
          this.$store.dispatch('communication/saveSupportSMSDelivery', {
            supportSMSDelivery: false
          })
        }

        this.$store.dispatch('communication/saveIsLegalCommunication', {
          isLegalCommunication: this.communication.isLegalCommunication
        })

        //Populate scheduling
        this.$store.dispatch('communication/saveDeliveryDate', {
          deliveryDate: this.communication.deliveryDate
        })

        //Populate Compose Messages
        this.$store.dispatch('communication/composedMailMessage', {
          mailMessage: this.communication.htmlContent
        })
        this.$store.dispatch('communication/composedEmailMessage', {
          emailMessage: this.communication.htmlEmailContent
        })
        this.$store.dispatch('communication/composedSmsMessage', {
          smsMessage: this.communication.htmlSmsContent
        })
        this.$store.dispatch('communication/saveEmailSubject', {
          emailSubject: this.communication.emailSubject
        })
        this.$store.dispatch('communication/saveSmsSubject', {
          smsSubject: this.communication.smsSubject
        })
        this.$store.dispatch('communication/saveIsFullDuplex', {
          isFullDuplex: this.communication.mailFullDuplex
        })
        this.$store.dispatch('communication/saveIsColor', {
          isColor: this.communication.mailColor
        })

        //Populate Delivery Audience
        let ownerIds = []
        if (
          this.communication.communicationDeliveryOwners != undefined &&
          this.communication.communicationDeliveryOwners
        ) {
          Array.from(this.communication.communicationDeliveryOwners).forEach(f => {
            if (f.ownerID) {
              ownerIds.push(f.ownerID)
            }
          })

          if (ownerIds && ownerIds.length > 0) {
            this.$store.dispatch('communication/saveDeliveryOwnerIDs', {
              selectedDeliveryOwnerIDs: ownerIds
            })
          }
        }
      } else {
        this.$store.dispatch('communication/saveSessionCommunicationID', {
          mailingCommunicationID: 0
        })
      }
    })
  },

  watch: {
    activeStep() {
      console.debug('in activeStep=' + this.activeStep)
      if (
        this.activeStep === 1 &&
        this.communication === undefined &&
        this.communication.communicationID === 0
      ) {
        this.addCommunication()
      }
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser']),
      ...mapGetters('communication', [
        'supportMailDelivery',
        'supportEmailDelivery',
        'supportSMSDelivery',
        'supportEmailDelivery',
        'isLegalCommunication',
        'mailingTypeID',
        'mailingCommunicationID'
      ])
    })
  },

  data,
  methods,

  i18n: {
    messages: {
      en: { mailingCommunications: { title: 'Communication' } }
    }
  }
}
</script>
<style lang="scss">
.uploader {
  .rectangle {
    width: 244px;
    height: 107px;
    margin: 13px 16px 11px 53px;
    padding: 18px 25px 8px 3px;
    border-radius: 4px;
    border: #979797;
  }
  .Drag-Drop-your-fil {
    width: 192px;
    height: 34px;
    margin: 7px 0 8px 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #838383;
  }

  .Drag-Drop-your-fil .text-style-1 {
    font-weight: bold;
    color: #6e7776;
  }

  img.Fill-1-Copy-11 {
    width: 24px;
    height: 30px;
    margin: 0 85px 7px 83px;
    object-fit: contain;
  }
}
</style>
