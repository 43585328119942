<template>
  <ListMailingCommunications :communicationID="parseInt(communicationID)" />
</template>

<script>
import { mapState } from 'vuex'
import ListMailingCommunications from '@/pages/Shared/mailingCommunications/List'

import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'BoardCommunications',

  components: {
    ListMailingCommunications
  },

  data,
  methods,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {},

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { mailingCommunications: { title: 'Communications' } }
    }
  }
}
</script>

<style></style>
