import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  showFilters: false,
  selectedCommunication: null,
  toggle: false,
  togglePlus: 'icon-pluscircle',
  toggleIndex: 0,
  communications: [],
  communicationID: 0,
  isReadOnly: false,
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'description',
      label: 'Description',
      aria: 'Description',
      width: '40%',
      sortable: true,
      searchable: true
    },
    {
      field: 'compositionMethod',
      label: 'Composition Method',
      aria: 'Composition Method',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'mailTypeName',
      label: 'Mail Type',
      aria: 'Mail Type',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'mailingDescription',
      label: 'Mailing',
      aria: 'Mailing',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'deliveryAudience',
      label: 'Delivery Audience',
      aria: 'Delivery Audience',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'createdDate',
      label: 'Created',
      width: '10%',
      date: true,
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
