/*
  import { mailingCommunicationStore } from '@/services/MailingCommunications/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'
import moment from 'moment'
import _get from 'lodash/get'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await mailingCommunicationStore.dispatch ('getCommunicationList', {
      hoaId
    });
  */

  async getCompositionMethodList({}) {
    try {
      let hardCodedList = [
        { value: '', label: '<-Composition Method->' },
        { value: 'pdf-document', label: 'Pdf Document' },
        { value: 'html', label: 'Html' }
      ]

      if (isDebug == true)
        console.debug('getCompositionMethodList=' + JSON.stringify(hardCodedList))

      return {
        list: hardCodedList
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the composition methods list.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getEmailPriorityList({}) {
    try {
      let hardCodedList = [
        { value: '', label: '<-Email Priority->' },
        { value: 'low', label: 'Low' },
        { value: 'normal', label: 'Normal' },
        { value: 'high', label: 'High' }
      ]

      if (isDebug == true) console.debug('getEmailPriorityList=' + JSON.stringify(hardCodedList))

      return {
        list: hardCodedList
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the email priority list.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getDeliveryAudienceList({}) {
    try {
      let hardCodedList = [
        { value: '', label: '<-Delivery Audience->' },
        { value: 'all-owners', label: 'All Owners' },
        { value: 'selected-owners', label: 'Selected Owners' }
      ]

      if (isDebug == true) console.debug('getDeliveryAudienceList=' + JSON.stringify(hardCodedList))

      return {
        list: hardCodedList
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the delivery audience list.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getMailingList({}, { hoaID }) {
    console.log('in getMailingList...' + hoaID)
    try {
      const results = await kms.get(`/Mailings/Mailing/List?hoaID=${hoaID}`)

      if (isDebug == true) console.debug('getMailingList log=' + JSON.stringify(results))

      let mailings = results.results.map(m => ({
        ...m,
        label: `${m.description} - ${moment(m.createdDate).format('MM/DD/YYYY')} - ${
          m.mailingType.name
        }`,
        value: m.mailingID
      }))

      mailings.unshift({ value: '', label: '<-Mailings->' })

      return {
        list: mailings
      }
    } catch (exception) {
      notifyProblem(`The mailing list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getMailingTypeList({}) {
    console.log('in getMailingTypeList...')
    try {
      const results = await kms.get(`/Mailings/MailingType/List`)

      if (isDebug == true) console.debug('getMailingTypeList log=' + JSON.stringify(results))

      let mailingTypes = results.results.map(m => ({
        ...m,
        label: `${m.name}`,
        value: m.mailingTypeID
      }))

      mailingTypes.unshift({ value: '', label: '<-Mailing Types->' })

      return {
        list: mailingTypes
      }
    } catch (exception) {
      notifyProblem(`The mailing type list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getCommunicationList({}, { hoaID }) {
    console.log('in getCommunicationList...' + hoaID)
    try {
      const results = await kms.get(`/MailingComponents/Communication/List?hoaID=${hoaID}`)

      if (isDebug == true) console.debug('getCommunicationList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The communications list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getCommunicationCostEstimateList({}, { communicationID }) {
    console.log('in getCommunicationCostEstimateList...' + communicationID)
    try {
      const results = await kms.post(
        `/MailingComponents/Communication/RetrieveCostEstimatesForCommunication?communicationID=${communicationID}`
      )

      if (isDebug == true)
        console.debug('getCommunicationCostEstimateList log=' + JSON.stringify(results))

      return {
        list: results.costEstimates,
        results,
        grandTotal: results.grandTotalCostEstimate,
        message: ''
      }
    } catch (exception) {
      console.debug(exception)
      if (exception.toString().indexOf('validation') > -1) {
        console.debug('exception in send communication=' + exception.message)
        return {
          list: null,
          grandTotal: 0,
          message: exception.message
        }
      } else {
        console.debug('The communication cost estimate list could not be retrieved.')
        console.error(exception)
      }
    }

    return {
      list: [],
      results: null,
      grandTotal: 0,
      message: ''
    }
  },

  async deleteCommunication({ dispatch }, { communicationID, done }) {
    try {
      const path = `/MailingComponents/Communication/${communicationID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results) {
        notifyMessage(`Successfully deleted the communication.`)
        done()
      } else {
        notifyError('There was a problem deleting the communication.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting the communication.`)
      console.error(exception)
    }
  },

  async addDeliveryOwners({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in addDeliveryOwners' + payload.ownerIDs)
    try {
      const ownerIDs = payload.ownerIDs
      const results = await kms.post(
        `/MailingComponents/CommunicationDeliveryOwner/AddCollectionOfOwners`,
        {
          communicationID: payload.communicationID,
          ownerIDs
        }
      )

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results && results === true) {
        console.debug(`Successfully added your selected homeowners.`)
        done({ detail: results })
      } else {
        console.debug('There was a problem adding this target audience.')
      }
    } catch (exception) {
      console.debug(`There was a problem adding this target audience.`)
      console.error(exception)
    }
  },

  async sendEmailMessage({ dispatch }, { hoaID, subject, message, done }) {
    if (isDebug == true) console.debug('...in sendEmailMessage')

    try {
      const results = await kms.post(
        `/MailingComponents/Communication/SendEmailMessage?hoaID=${hoaID}`,
        {
          subject,
          message
        }
      )

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results) {
        console.debug(`Successfully sent a test email. Please check your email.`)
        done({ list: results })
      } else {
        notifyMessage('There was a problem sending a test email.')
      }
    } catch (exception) {
      notifyError(`There was a problem sending a test email.`)
      console.error(exception)
    }
  },

  async sendCommunication({ dispatch }, { communicationID, done }) {
    if (isDebug == true) console.debug('...in sendCommunication=' + communicationID)
    try {
      const results = await kms.post(
        `/MailingComponents/Communication/SendCommunication?communicationID=${communicationID}`,
        {
          communicationID
        }
      )

      console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results) {
        console.debug(`This communication has been successfully sent for processing.`)
        done({ list: results, message: '' })
      } else {
        notifyMessage('There was a problem sending this communication.')
      }
    } catch (exception) {
      console.debug(exception)
      if (exception.toString().indexOf('validation') > -1) {
        console.debug('exception in send communication=' + exception.message)
        done({ list: null, message: exception.message })
      } else if (exception.toString().indexOf('future date') > -1) {
        console.debug('exception in send communication=' + exception.message)
        done({ list: null, message: exception.message })
      } else {
        notifyError('There was a problem sending this communication.')
      }
    }
  },

  async addCommunication({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in addCommunication')
    try {
      const results = await kms.post(`/MailingComponents/Communication`, {
        hoaID: payload.hoaID,
        compositionMethod: payload.compositionMethod,
        htmlContent: payload.htmlContent,
        htmlSmsContent: payload.htmlSmsContent,
        htmlEmailContent: payload.htmlEmailContent,
        documentID: payload.documentID,
        mailingID: payload.mailingID,
        mailingTypeID: payload.mailingTypeID,
        supportMailDelivery: payload.supportMailDelivery,
        supportEmailDelivery: payload.supportEmailDelivery,
        supportSMSDelivery: payload.supportSMSDelivery,
        supportPushDelivery: payload.supportPushDelivery,
        isLegalCommunication: payload.isLegalCommunication,
        emailSubject: payload.emailSubject,
        emailPriority: payload.emailPriority,
        smsSubject: payload.smsSubject,
        pushSubject: payload.pushSubject,
        deliveryAudience: payload.deliveryAudience,
        description: payload.description
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.communicationID > 0) {
        console.debug(`Successfully added mailing communication.`)
        done({ detail: results })
      } else {
        console.debug('There was a problem adding this mailing communication.')
      }
    } catch (exception) {
      console.debug(`There was a problem adding this mailing communication.`)
      console.error(exception)
    }
  },

  async addCommunicationAttachment({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in addCommunicationAttachment')
    try {
      const results = await kms.post(`/MailingComponents/CommunicationAttachment`, {
        communicationID: payload.communicationID,
        cursorPosition: payload.cursorPosition,
        supportsMail: payload.supportsMail,
        supportsEmail: payload.supportsEmail,
        description: payload.description
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.communicationID > 0) {
        console.debug(`Successfully added a communication attachment record.`)
        done({ detail: results })
      } else {
        console.debug('There was a problem adding this mailing communication attachment record.')
      }
    } catch (exception) {
      console.debug(`There was a problem adding this mailing communication attachment record.`)
      console.error(exception)
    }
  },

  async deleteCommunicationAttachment({ dispatch }, { communicationAttachmentID, done }) {
    try {
      const path = `/MailingComponents/CommunicationAttachment/${communicationAttachmentID}?forceDelete=true`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        console.debug(`Successfully deleted the communication attachment.`)
        done()
      } else {
        console.debug('There was a problem deleting the communication attachment.')
      }
    } catch (exception) {
      console.debug(`There was a problem deleting the communication attachment.`)
      console.error(exception)
    }
  },

  async uploadCommunicationAttachmentDocument({}, { payload }) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      const path = `/MailingComponents/CommunicationAttachment/StoreDocument?communicationAttachmentID=${payload.communicationAttachmentID}`
      const documentFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (isDebug == true)
        console.debug(
          'uploadCommunicationAttachmentDocument with payload=' + JSON.stringify(documentFile)
        )

      return {
        returnedfile: documentFile
      }
    } catch (exception) {
      notifyProblem('There was a problem uploading the attachment.')
      console.debug(exception)
    }

    return {
      returnedfile: null
    }
  },

  async getCommunicationAttachmentDocumentGetFile({}, { params = {} }) {
    try {
      const result = await kms.get(`/MailingComponents/CommunicationAttachment/GetFile`, {
        params
      })

      if (isDebug == true) console.debug('getCommunicationAttachmentDocumentGetFile=' + result)

      return {
        returned: result
      }
    } catch (exception) {
      console.debug(`There was a problem retrieving the attachment.`)
      console.error(exception)
    }

    return {
      returned: null
    }
  },

  async getCommunicationById({}, { communicationID }) {
    try {
      console.debug('in getCommunicationById...')
      let path = `/MailingComponents/Communication/${communicationID}`
      const result = await kms.get(path)

      if (isDebug == true) console.debug('getCommunicationById=' + JSON.stringify(result))

      return {
        result: result
      }
    } catch (exception) {
      console.debug(`There was a problem retrieving this communication detail.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  async updateCommunicationMessage({ dispatch }, { params = {}, done }) {
    try {
      console.debug(
        'params.isLegalCommunication=' + params.isLegalCommunication
          ? params.isLegalCommunication
          : result.isLegalCommunication
      )
      let path = `/MailingComponents/Communication/${params.communicationID}`
      let result = await kms.get(path)

      result.htmlContent = params.htmlContent ? params.htmlContent : result.htmlContent
      result.htmlSmsContent = params.htmlSmsContent ? params.htmlSmsContent : result.htmlSmsContent
      result.smsSubject = params.smsSubject ? params.smsSubject : result.smsSubject
      result.htmlEmailContent = params.htmlEmailContent
        ? params.htmlEmailContent
        : result.htmlEmailContent
      result.emailSubject = params.emailSubject ? params.emailSubject : result.emailSubject
      result.emailPriority = params.emailPriority ? params.emailPriority : result.emailPriority
      result.supportMailDelivery = params.supportMailDelivery
      result.supportEmailDelivery = params.supportEmailDelivery
      result.supportSMSDelivery = params.supportSMSDelivery
      result.supportPushDelivery = params.supportPushDelivery
        ? params.supportPushDelivery
        : result.supportPushDelivery
      result.mailFullDuplex =
        params.mailFullDuplex != null ? params.mailFullDuplex : result.mailFullDuplex
      result.mailColor = params.mailColor != null ? params.mailColor : result.mailColor
      result.isLegalCommunication = params.isLegalCommunication
      result.description = params.description ? params.description : result.description
      result.documentID = params.documentID ? params.documentID : result.documentID
      result.mailingTypeID = params.mailingTypeID ? params.mailingTypeID : result.mailingTypeID
      result.deliveryAudience = params.deliveryAudience
        ? params.deliveryAudience
        : result.deliveryAudience
      result.compositionMethod = params.compositionMethod
        ? params.compositionMethod
        : result.compositionMethod
      result.deliveryDate = params.deliveryDate
        ? params.deliveryDate.date === moment('01/01/1900').date
          ? null
          : params.deliveryDate
        : result.deliveryDate

      if (result.mailing !== undefined && result.mailing) {
        result.mailing = null
      }

      if (result.mailingType !== undefined && result.mailingType) {
        result.mailingType = null
      }

      console.debug('result get prior to update=' + JSON.stringify(result))

      const results = await kms.put(`/MailingComponents/Communication`, result)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.communicationID > 0) {
        console.debug(`Successfully updated this communication.`)
        done({ detail: results })
      } else {
        notifyError('There was a problem updating this communication.')
      }
    } catch (exception) {
      notifyError(`There was a problem processing the update for this communication.`)
      console.error(exception)
    }
  },

  async updateCommunication({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/MailingComponents/Communication`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.communicationID > 0) {
        notifyMessage(`Successfully updated this communication.`)
        done({ details: results })
      } else {
        notifyError('There was a problem updating this communication.')
      }
    } catch (exception) {
      notifyError(`There was a problem processing the update for this communication.`)
      console.error(exception)
    }
  },

  async getCommunicationDocumentGetFile({}, { params = {} }) {
    try {
      const result = await kms.get(`/MailingComponents/Communication/GetFile`, {
        params
      })

      if (isDebug == true) console.debug('getCommunicationDocumentGetFile=' + result)

      return {
        returned: result
      }
    } catch (exception) {
      console.debug(`There was a problem retrieving the communication document file.`)
      console.error(exception)
    }

    return {
      returned: null
    }
  },

  async uploadCommunicationDocument({}, { payload }) {
    const formData = new FormData()
    formData.append('file', payload.file)

    try {
      const path = `/MailingComponents/Communication/StoreDocument?communicationID=${payload.communicationID}`
      const communicationDocumentFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (isDebug == true)
        console.debug(
          'communicationDocumentFile with payload=' + JSON.stringify(communicationDocumentFile)
        )

      return {
        returnedfile: communicationDocumentFile
      }
    } catch (exception) {
      notifyProblem('There was a problem uploading the communication document.')
      console.error(exception)
    }

    return {
      returnedfile: null
    }
  },

  async getConversationGetFile({}, { params = {} }) {
    try {
      const result = await kms.get(`/Messaging/PrivateThreadMessageAttachment/GetAttachmentFile`, {
        params
      })

      if (isDebug == true) console.debug('getConversationGetFile=' + result)

      return {
        returned: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the conversation attachment file.`)
      console.error(exception)
    }

    return {
      returned: null
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const mailingCommunicationStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
