<template>
  <div>
    <div class="subtitle force-center pt-5">
      Please review your communication to ensure accuracy before sending.
    </div>
    <div v-if="communication">
      <div class="subtitle columns">
        <div class="pb-4 column is-4">
          <div class="columns">
            <div class="column is-9"><label class="label">Delivery Methods</label></div>
            <div class="column is-3">
              <b-button
                class="is-primary btn-sm is-small"
                style="width: 50px; height: 25px;"
                @click.prevent="redirectToDeliveryMethods"
                >{{ `${isReadOnly === true || isSent === true ? 'View' : 'Edit'}` }}</b-button
              >
            </div>
          </div>
          <div class="pb-3">
            <div>Description</div>
            <div
              class="small-text"
              v-if="communication.description !== undefined && communication.description"
            >
              {{ communication.description }}
            </div>
            <div class="small-red-text" v-else>Warning: Description is needed</div>
          </div>
          <div class="pb-3" v-if="communication.supportMailDelivery">
            <div>Postal Mail Service</div>
            <div
              class="small-text"
              v-if="
                communication.compositionMethod !== undefined &&
                  communication.compositionMethod &&
                  communication.compositionMethod === 'pdf-document' &&
                  pdfFileName
              "
            >
              {{ pdfFileName }}
            </div>
            <div
              class="small-text"
              v-else-if="communication.htmlContent !== undefined && communication.htmlContent"
              v-html="communication.htmlContent"
            ></div>
            <div class="small-red-text" v-else>Warning: Content is needed</div>
            <div
              class="small-text"
              v-if="
                communication.isLegalCommunication !== undefined &&
                  communication.isLegalCommunication === true
              "
            >
              Legal Communication
            </div>
          </div>
          <div class="pb-3" v-if="communication.supportEmailDelivery">
            <div>Email</div>
            <div
              class="small-text"
              v-if="communication.htmlEmailContent !== undefined && communication.htmlEmailContent"
              v-html="communication.htmlEmailContent"
            ></div>
            <div class="small-red-text" v-else>Warning: Content is needed</div>
          </div>
          <div class="pb-3" v-if="communication.supportSMSDelivery">
            <div>SMS</div>
            <div
              class="small-text"
              v-if="communication.htmlSmsContent !== undefined && communication.htmlSmsContent"
              v-html="communication.htmlSmsContent"
            ></div>
            <div class="small-red-text" v-else>Warning: Content is needed</div>
          </div>
        </div>
        <div class="pb-5 column is-4">
          <div class="columns">
            <div class="column is-9"><label class="label">Scheduling</label></div>
            <div class="column is-3">
              <b-button
                class="is-primary btn-sm is-small"
                style="width: 50px; height: 25px;"
                @click.prevent="redirectToScheduling"
                >{{ `${isReadOnly === true || isSent === true ? 'View' : 'Edit'}` }}</b-button
              >
            </div>
          </div>
          <div v-if="communication.deliveryDate">
            <div>Deliver On:</div>
            <div class="small-text">{{ `${formattedDate} ${abbreviatedTimezone || ''}` }}</div>
          </div>
          <div v-else>Deliver Immediately</div>
        </div>
        <div class="pb-5 column is-4">
          <div class="columns">
            <div class="column is-9"><label class="label">Target Audiences</label></div>
            <div class="column is-3">
              <b-button
                class="is-primary btn-sm is-small"
                style="width: 50px; height: 25px;"
                @click.prevent="redirectToTargetAudiences"
                >{{ `${isReadOnly === true || isSent === true ? 'View' : 'Edit'}` }}</b-button
              >
            </div>
          </div>
          <div v-if="communication.deliveryAudience === 'all-owners'">
            All Owners
          </div>
          <div v-if="communication.deliveryAudience === 'selected-owners'">
            <div>Selected Owners</div>
            <div class="small-text" v-if="selectedDeliveryOwners">
              <div>{{ `Delivering to ${selectedDeliveryOwners.length || 0} homeowners` }}</div>
            </div>
            <div class="small-red-text" v-else>Warning: Content is needed</div>
          </div>
        </div>
      </div>
      <div>
        <label
          v-if="
            processedCommunications &&
              processedCommunications.results &&
              processedCommunications.numberQueued !== undefined &&
              processedCommunications.numberQueued &&
              processedCommunications.numberQueued > 0
          "
          class="label"
          >Processing of Communication</label
        >
        <div class="small-text pb-5" v-if="processedCommunications.results">
          <div class="font-bold">
            {{ `Total Communications: ${processedCommunications.numberQueued}` }}
          </div>
          <div class="font-bold">
            {{ `Total Successfully Sent: ${processedCommunications.numberSuccessful}` }}
          </div>
          <div class="font-bold">{{ `Total Failed: ${processedCommunications.numberFailed}` }}</div>
          <div v-if="processedCommunications.numberMailedQueued > 0">
            <div class="font-larger2 pt-2">{{ `Postal Mail Service` }}</div>
            <div class="font-bold">
              {{ `Total Postal Communications: ${processedCommunications.numberMailedQueued}` }}
            </div>
            <div class="font-bold">
              {{ `Total Postal Mail Sent: ${processedCommunications.numberMailedSuccessful}` }}
            </div>
            <div class="font-bold pb-2">
              {{ `Total Postal Failed: ${processedCommunications.numberMailedFailed}` }}
            </div>
            <div class="" v-for="(item, index) in processedCommunications.results" :key="index">
              <div v-if="item.deliveryMethod === 'mail'">
                <div v-if="item.deliveryResult === 'failure'">
                  {{ `${item.recipientName}: ${item.message}` }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="processedCommunications.numberEmailedQueued > 0">
            <div class="font-larger2 pt-2">{{ `Email Service` }}</div>
            <div class="font-bold">
              {{ `Total Email Communications: ${processedCommunications.numberEmailedQueued}` }}
            </div>
            <div class="font-bold">
              {{ `Total Email Sent: ${processedCommunications.numberEmailedSuccessful}` }}
            </div>
            <div class="font-bold pb-2">
              {{ `Total Email Failed: ${processedCommunications.numberEmailedFailed}` }}
            </div>
            <div class="" v-for="(item2, index2) in processedCommunications.results" :key="index2">
              <div v-if="item2.deliveryMethod === 'email'">
                <div v-if="item2.deliveryResult === 'failure'">
                  {{ `${item2.recipientName}: ${item2.message}` }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="processedCommunications.numberTextedQueued">
            <div class="font-larger2 pt-2">{{ `Mobile Service` }}</div>
            <div class="font-bold">
              {{
                `Total Text Message Communications: ${processedCommunications.numberTextedQueued}`
              }}
            </div>
            <div class="font-bold">
              {{ `Total Texts Sent: ${processedCommunications.numberTextedSuccessful}` }}
            </div>
            <div class="font-bold pb-2">
              {{ `Total Texts Failed: ${processedCommunications.numberTextedFailed}` }}
            </div>
            <div class="" v-for="(item3, index3) in processedCommunications.results" :key="index3">
              <div v-if="item3.deliveryResult === 'failure'">
                {{ `${item3.recipientName}: ${item3.message}` }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pb-5" v-if="validationMessages">
        <label class="label">Validation</label>
        <div class="redcurrency" v-if="validationMessages">{{ validationMessages }}</div>
      </div>
      <div v-if="notValid === false">
        <label class="label font-larger3">{{ costEstimateLabel }}</label>
        <ModernTable
          :region="region"
          :filters="filters"
          :rows="rows"
          :columns="columns"
          height="480px"
          :condensed="true"
          :loading="loading"
          headerColor="#FFF"
        >
          <template v-slot:header>
            <div :style="{ width: '100%', height: '60px' }">
              <b-button
                type="is-ghost"
                @click="filters.show = !filters.show"
                :style="{ float: 'right', background: 'none', border: 0 }"
                :class="'filter-button ' + (filters.show ? 'active' : '')"
              >
                <b-icon
                  pack="mdi"
                  icon="filter-outline"
                  size="is-small"
                  style="display: inline-block"
                >
                </b-icon>
                <div style="display: inline-block">Filter</div>
              </b-button>
            </div>
          </template>
        </ModernTable>
      </div>
      <div class="float-right pr-5 pt-3">
        <div class="font-larger2">Total Delivery Cost</div>
        <div class="subtitle" style="font-weight: normal !important;">
          {{ `${grandTotal || 0}` | currency }}
        </div>
      </div>
      <div class="pt-3">
        <b-button
          class="is-primary"
          @click.prevent="sendCommunication"
          :disabled="isReadOnly === true || isSent === true || notValid === true"
          >{{
            `${this.communication.deliveryDate ? 'Confirm & Schedule' : 'Confirm & Deliver'}`
          }}</b-button
        >
        <span class="pl-3">&nbsp;</span>
        <b-button class="is-primary" @click.prevent="closeCommunication">Close</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mailingCommunicationStore } from '@/services/MailingCommunications/store'
import { mapState, mapGetters } from 'vuex'
import { notifyMessage, notifyError } from '@/services/notify'
import { hoaUtility } from '@/services/Hoa/store'
import moment from 'moment'
import ModernTable from '@/components/tables/Modern/Table'

export default {
  name: 'Review',

  props: {
    isReadOnly: Boolean,
    step: Number,
    closeCommunication: Function
  },

  components: {
    ModernTable
  },

  data() {
    return {
      communication: null,
      formattedDate: '',
      validationMessages: '',
      processedCommunications: [],
      costEstimates: [],
      isSent: false,
      loading: false,
      costEstimateLabel: 'Cost Estimates',
      grandTotal: 0,
      notValid: false,
      abbreviatedTimezone: '',

      filters: {
        show: false
      },

      region: {},

      rows: [],

      columns: [
        {
          field: 'lineItem',
          label: 'Line Item',
          aria: 'Line Item',
          width: '40%',
          sortable: true,
          searchable: true
        },
        {
          field: 'quantity',
          label: 'Quantity',
          aria: 'Quantity',
          width: '20%',
          sortable: true,
          searchable: true
        },
        {
          field: 'cost',
          label: 'Cost\n(per item)',
          aria: 'Cost\n(per item)',
          width: '20%',
          sortable: true,
          searchable: true
        },
        {
          field: 'total',
          label: 'Total',
          aria: 'Total',
          width: '20%',
          sortable: true,
          searchable: true
        }
      ]
    }
  },

  methods: {
    redirectToDeliveryMethods() {
      this.$emit('update:step', (this.step = 0))
    },

    redirectToScheduling() {
      this.$emit('update:step', (this.step = 1))
    },

    redirectToTargetAudiences() {
      this.$emit('update:step', (this.step = 3))
    },

    determineRows() {
      const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      })

      if (this.costEstimates !== undefined && this.costEstimates) {
        Array.from(this.costEstimates).forEach(f => {
          if (f && f.quantity > 0) {
            const estimate = {
              lineItem: f.lineItemName,
              quantity: f.quantity,
              cost: currencyFormatter.format(f.costPerItem),
              total: currencyFormatter.format(f.lineTotal)
            }

            if (estimate) {
              this.rows.push(estimate)
            }
          }
        })
      }
    },

    async loadCurrentCommunication() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      this.validationMessages = ''
      console.debug('loadCurrentCommunication=' + JSON.stringify(this.communication))
      if (this.mailingCommunicationID !== undefined && this.mailingCommunicationID) {
        await mailingCommunicationStore
          .dispatch('getCommunicationById', {
            communicationID: this.mailingCommunicationID
          })
          .then(async ({ result }) => {
            if (result) {
              this.communication = result
              this.formattedDate = moment(this.communication.deliveryDate).format('LLL')
              this.loadCommunicationCostEstimates()
              if (this.communication.mailingID !== undefined && this.communication.mailingID) {
                this.costEstimateLabel = 'Submitted Costs'
              }
            }
          })
      }

      loadingComponent.close()
    },

    async loadCommunicationCostEstimates() {
      console.debug('in loadCommunicationCostEstimates()...')

      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      var formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2
      })

      if (this.mailingCommunicationID && this.mailingCommunicationID > 0) {
        this.rows = []
        this.costEstimates = []
        await mailingCommunicationStore
          .dispatch('getCommunicationCostEstimateList', {
            communicationID: this.mailingCommunicationID
          })
          .then(({ list, results, grandTotal, message }) => {
            if (list) {
              this.costEstimates = list
              this.grandTotal = formatter.format(grandTotal || 0)
              this.notValid = false
            }

            if (message) {
              const valMessage = (message + '').replace('Error:', '').replace(';', '')
              this.validationMessages = valMessage.replace(';', '')
              this.notValid = true
              return
            }

            console.debug('results=' + JSON.stringify(results))

            if (results) {
              this.processedCommunications = results
              console.debug(
                'processedCommunications.numberQueued' + this.processedCommunications.numberQueued
              )
            }
          })
      }

      loadingComponent.close()
    },

    async sendCommunication() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      if (this.grandTotal && this.grandTotal === 0) {
        notifyError(
          'A cost estimate must be present prior to submitting this communication. Please make sure each step has been properly entered.'
        )
        return
      }

      try {
        this.validationMessages = ''
        if (this.mailingCommunicationID !== undefined && this.mailingCommunicationID) {
          await mailingCommunicationStore.dispatch('sendCommunication', {
            communicationID: this.mailingCommunicationID,
            done: async ({ list, message }) => {
              if (list && list.results) {
                if (list.results.length > 0) {
                  this.processedCommunications = list
                  this.costEstimateLabel = 'Submitted Costs'
                }

                if (list.costEstimates) {
                  this.costEstimates = []
                  this.rows = []
                  this.costEstimates = list.costEstimates
                  this.determineRows()
                }

                var formatter = new Intl.NumberFormat('en-US', {
                  style: 'decimal',
                  minimumFractionDigits: 2
                })

                this.grandTotal = formatter.format(list.grandTotalCostEstimate || 0)
              }

              if (message) {
                const valMessage = (message + '').replace('Error:', '').replace(';', '')
                this.validationMessages = valMessage.replace(';', '')
                return
              }

              notifyMessage('This communication was successfully sent.')
              this.isSent = true
              this.$emit('update:isReadOnly', (this.isReadOnly = true))
            }
          })
        }
      } catch (e) {
        console.debug('error messages=' + e.message)
        loadingComponent.close()
      }

      loadingComponent.close()
    },

    async loadHoaTimezone() {
      await hoaUtility
        .dispatch('getHoaById', {
          hoaID: this.hoaId && this.hoaId > 0 ? this.hoaId : 0
        })
        .then(({ result }) => {
          if (result) {
            if (result.timezoneName) {
              this.myTimezone = result.timezoneName
            } else {
              this.myTimezone = 'Not Setup'
            }
          }
        })

      this.$store.dispatch('communication/saveTimezone', {
        timezone: this.myTimezone
      })
    },

    abbreviateTimezone() {
      if (!this.timezone) {
        this.loadHoaTimezone()
      }

      const words = this.timezone.split(' ')

      for (let i = 0; i < words.length; i++) {
        this.abbreviatedTimezone = this.abbreviatedTimezone + words[i][0].toUpperCase()
      }
    }
  },
  mounted() {
    this.loadCurrentCommunication().then(() => {
      this.loadCommunicationCostEstimates().then(() => {
        this.determineRows()

        this.abbreviateTimezone()
      })
    })
  },

  watch: {
    step() {
      if (this.step === 4) {
        this.loadCurrentCommunication().then(() => {
          this.loadCommunicationCostEstimates().then(() => {
            this.determineRows()
          })
        })
      }
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('communication', [
        'supportMailDelivery',
        'supportEmailDelivery',
        'supportSMSDelivery',
        'isLegalCommunication',
        'mailingTypeID',
        'mailMessage',
        'pdfFileName',
        'mailingCommunicationID',
        'deliveryDate',
        'selectedDeliveryOwners',
        'communicationDescription',
        'timezone'
      ])
    })
  }
}
</script>
