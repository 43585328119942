import Button from '@/components/buttons/Button'
import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'
import parseStreet from '@/utilities/address/parseStreet'
import { escrowStore } from '@/services/Escrow/store'

export const methods = {
  determineRows: function(list) {
    const dateFormat = 'LL'

    let filteredList = list
    let needsInspected = false

    if (this.statusFilter) {
      if (this.statusFilter === 'pending')
        filteredList = filteredList.filter(f => f.completedDate === null)
      else if (this.statusFilter === 'complete') {
        filteredList = filteredList.filter(f => f.completedDate !== null)
      } else if (this.statusFilter === 'inspection') {
        filteredList = filteredList.filter(
          f => f.inspectionDate === null && f.inspectionRequired == true
        )

        needsInspected = true
      }
      if (this.isDebug === true) console.debug('filtered = ' + JSON.stringify(filteredList))
    }

    var rows = filteredList.map(entry => {
      const parsedStreet = parseStreet({
        address: _get(entry, 'unit.address', '')
      })

      const escrowID = _get(entry, 'escrowID', 0)
      const lotNumber = _get(entry, 'unit.lotNumber', '')
      const buyerName = _get(entry, 'buyerName', '')
      const closingDate = parseDate(_get(entry, 'closingDate', null), dateFormat)
      const ownerID = _get(entry, 'unit.primaryOwnerID', '')
      const submittedBy = `Add Homeowner - ${_get(entry, 'buyerName', '')}`

      return {
        lotNumber,
        address: parsedStreet,
        buyerName,
        closingDate,
        details: {
          component: Button,
          props: {
            onClick: () => {
              this.processDetail(entry)
            },
            text: 'Details'
          }
        },
        askquestion: {
          component: Button,
          props: {
            onClick: () => {
              this.redirectToAskaQuestion(_get(entry, 'escrowID', 0), ownerID, submittedBy)
            },
            text: 'Ask a Question'
          }
        },
        inspection: {
          component: Button,
          props: {
            onClick: () => {
              this.processeInspectionComplete(escrowID)
            },
            text: 'Inspection Complete',
            visible: needsInspected === true ? true : false
          }
        }
      }
    })

    this.rows = rows
  },

  redirectToAskaQuestion(id, ownerId, label) {
    console.debug('escrow params=' + id + ', ' + ownerId + ', ' + label)

    this.$router.push({
      name: 'newconversation',
      params: {
        escrowid: id,
        escrowownerid: ownerId,
        escrowlabel: label
      }
    })
  },

  async retrieveEscrowInfo() {
    await escrowStore
      .dispatch('getListValues', {
        name: 'escrow-submission'
      })
      .then(({ list, extendedResult }) => {
        console.debug('results=' + JSON.stringify(list))
        console.debug('extendedResult=' + extendedResult)

        this.escrowPropertiesList = list
        this.escrowExtended = extendedResult
        this.loading = false
      })
  },

  processeInspectionComplete(escrowID) {
    if (escrowID) {
      this.toggle = true
      this.selectedEscrowID = escrowID
    }
  },

  async reload() {
    console.debug('in reload...')
    this.loading = true

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    await escrowStore
      .dispatch('getEscrowList', {
        hoaID: this.hoaId
      })
      .then(({ list }) => {
        this.determineRows(list)
        this.loading = false
      })

    await this.retrieveEscrowInfo()

    loadingComponent.close()

    this.loading = false
  },

  processDetail(escrow) {
    if (escrow) {
      const path = `/escrowTabs/${escrow.escrowID}/${escrow.buyerName}/escrowDetails#tab`
      this.$router.push(path)
    }
  }
}
