import Button from '@/components/buttons/Button'
import _get from 'lodash/get'
import { mailingCommunicationStore } from '@/services/MailingCommunications/store'
import Linked from './../components/Linked'
import moment from 'moment'

export const methods = {
  determineRows: function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      const dateFormat = 'LL'
      const communicationID = _get(entry, 'communicationID', 0)
      const description = _get(entry, 'description', '')
      const compositionMethod = _get(entry, 'compositionMethod', '')
      const deliveryAudience = _get(entry, 'deliveryAudience', '')
      const mailingDescription = _get(entry, 'mailing.description', '')
      const mailingTypeName = _get(entry, 'mailingType.name', '')
      const mailingID = _get(entry, 'mailing.mailingID', 0)
      let createdDate = _get(entry, ['createdDate'], '')
      let date = createdDate

      let mailingLink = `<a class="href-overflow-wrap" href="maildeliveryitems/${mailingID}/${mailingID}/${mailingDescription}">${mailingDescription}</a>`

      try {
        date = moment(createdDate).format(dateFormat)
      } catch (exception) {
        console.error(exception)
      }

      return {
        description,
        compositionMethod,
        mailingTypeName,
        mailingDescription: {
          component: Linked,
          props: {
            text: mailingDescription ? mailingLink : ''
          }
        },
        deliveryAudience,
        createdDate: {
          date,
          format: dateFormat
        },
        details: {
          component: Button,
          props: {
            onClick: () => {
              this.processEdit(
                entry,
                mailingDescription !== undefined &&
                  mailingDescription &&
                  mailingDescription.length > 0
              )
            },
            text:
              mailingDescription !== undefined &&
              mailingDescription &&
              mailingDescription.length > 0
                ? 'View'
                : 'Edit'
          }
        },
        erase: {
          component: Button,
          props: {
            onClick: () => {
              this.confirmDelete(description, communicationID)
            },
            text: 'Delete',
            visible:
              mailingDescription !== undefined &&
              mailingDescription &&
              mailingDescription.length > 0
                ? false
                : true
          }
        }
      }
    })

    this.rows = rows
  },

  processItems(mailing) {
    if (mailing) {
      this.$router.push({
        name: 'maildeliveryitems',
        params: {
          id: mailing.mailingID,
          mailingid: mailing.mailingID,
          title: mailing.description
        }
      })
    }
  },

  confirmDelete(description, id) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Communication',
      message: `Are you sure you want to <b>delete</b> this communication, '${description}'?`,
      confirmText: 'Delete Communication',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteCommunication(id)
    })
  },

  async deleteCommunication(id) {
    console.debug('deleteCommunication...')
    await mailingCommunicationStore.dispatch('deleteCommunication', {
      communicationID: id,
      done: async () => {
        this.reload()
      }
    })
  },

  async loadCommunications() {
    console.debug('in loadCommunications()...')

    await mailingCommunicationStore
      .dispatch('getCommunicationList', {
        hoaID: this.hoaId
      })
      .then(({ list }) => {
        if (list) {
          this.determineRows(list)
        }
      })
  },

  addCommunication() {
    console.debug('add addCommunication...')
    this.selectedCommunication = null
    this.$store.dispatch('communication/initStates')
    this.toggle = true
    this.isReadOnly = false
  },

  processEdit(communication, mode) {
    if (communication) {
      this.selectedCommunication = communication
      this.toggle = true
      this.isReadOnly = mode || false
    }
  },

  async reload() {
    this.loading = true

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    this.rows = []
    await this.loadCommunications()

    loadingComponent.close()

    this.loading = false
  }
}
