export const methods = {
  async reload() {
    this.loading = true

    this.escrowID = this.$route.params.id

    console.debug('escrowID=' + this.escrowID)

    this.loading = false
  },

  returnToParent() {
    console.debug('returnToParent=' + this.mailingID)

    this.$router.push({
      name: 'escrow'
    })
  }
}
