<template>
  <div>
    <div v-if="visiblePage === 'DeliveryDate'">
      <div class="force-center subtitle pb-5 pt-5">
        When do you want this delivered?
      </div>
      <div class="subtitle force-center">
        <button
          type="button"
          @click.prevent="processImmediateDelivery"
          class="button is-link is-light"
          :disabled="isReadOnly === true"
        >
          Immediately
        </button>
        <span class="pl-2 pr-2" style="padding-top: 10px; vertical-align: middle !important;"
          >- or -</span
        >

        <button
          type="button"
          @click.prevent="redirectToFutureDate"
          class="button is-link is-light"
          :disabled="isReadOnly === true"
        >
          Future Date
        </button>
      </div>
      <div class="small-red-text force-center">
        Note: Immediate deliveries are delayed by 15 minutes to allow for changes.
      </div>
    </div>
    <div v-if="visiblePage === 'FutureDate'" class="force-center">
      <div class="force-center subtitle pb-5">
        Select the future date and time for delivery.
      </div>
      <div class="force-center columns">
        <div class="force-center column is-9">
          <DateTimePicker :disabled="isReadOnly === true" ref="futureDeliveryDate" :point="point" />
        </div>
        <div class="column is-2">
          <b-button
            :disabled="isReadOnly === true"
            type="is-primary is-small"
            @click.prevent="processFutureDate"
            >Save</b-button
          >
        </div>
      </div>
      <div class="small-text" v-if="myTimezone">{{ `Timezone: ${myTimezone || ''}` }}</div>
      <div class="pt-5">
        <span class="pr-5"
          ><b-button type="is-primary is-small" @click.prevent="redirectToDeliveryDate"
            >Previous</b-button
          ></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DateTimePicker from '@/components/inputs/DateTimePicker'
import { mailingCommunicationStore } from '@/services/MailingCommunications/store'
import { hoaUtility } from '@/services/Hoa/store'
import { notifyMessage } from '@/services/notify'
import moment from 'moment'

export default {
  name: 'Scheduling',

  props: {
    communication: Object,
    isReadOnly: Boolean,
    step: Number
  },

  components: {
    DateTimePicker
  },
  data() {
    return {
      visiblePage: 'DeliveryDate',
      loading: false,
      point: new Date().toISOString(),
      pointFormatted: '',
      myTimezone: ''
    }
  },
  moment,
  methods: {
    async redirectToFutureDate() {
      this.visiblePage = 'FutureDate'
      this.point =
        this.deliveryDate && this.deliveryDate.length > 0
          ? new Date(this.deliveryDate).toISOString()
          : new Date().toISOString()
      this.pointFormatted = moment(this.point).format('LLL')
    },
    async processFutureDate() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      const meetingRefDate = this.$refs.futureDeliveryDate.getValue()

      console.debug('meetingRefDate=' + meetingRefDate.value)

      this.$store.dispatch('communication/saveDeliveryDate', {
        deliveryDate: meetingRefDate.value
      })

      const params = {
        communicationID: this.mailingCommunicationID,
        supportMailDelivery: this.supportMailDelivery,
        supportEmailDelivery: this.supportEmailDelivery,
        supportSMSDelivery: this.supportSMSDelivery,
        supportPushDelivery: this.supportPushDelivery,
        isLegalCommunication: this.isLegalCommunication,
        mailFullDuplex: this.isDuplexParam,
        mailColor: this.isColorParam,
        deliveryDate: meetingRefDate.value
      }

      await mailingCommunicationStore.dispatch('updateCommunicationMessage', {
        params,
        done: async ({ detail }) => {
          if (detail) {
            this.communicationID = detail.communicationID

            notifyMessage('This message is set to be delivered at a future date.')
          }
        }
      })

      loadingComponent.close()
    },

    redirectToDeliveryDate() {
      this.visiblePage = 'DeliveryDate'
    },

    async processImmediateDelivery() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      this.$store.dispatch('communication/saveDeliveryDate', {
        deliveryDate: null
      })

      const params = {
        communicationID: this.mailingCommunicationID,
        supportMailDelivery: this.supportMailDelivery,
        supportEmailDelivery: this.supportEmailDelivery,
        supportSMSDelivery: this.supportSMSDelivery,
        supportPushDelivery: this.supportPushDelivery,
        isLegalCommunication: this.isLegalCommunication,
        description: this.communicationDescription,
        mailFullDuplex: null,
        mailColor: null,
        deliveryDate: moment('01/01/1900')
      }

      await mailingCommunicationStore.dispatch('updateCommunicationMessage', {
        params,
        done: async ({ detail }) => {
          if (detail) {
            this.communicationID = detail.communicationID

            notifyMessage('This message is set to be delivered immediately')
          }
        }
      })

      loadingComponent.close()
    },

    async loadHoaTimezone() {
      await hoaUtility
        .dispatch('getHoaById', {
          hoaID: this.hoaId && this.hoaId > 0 ? this.hoaId : 0
        })
        .then(({ result }) => {
          if (result) {
            if (result.timezoneName) {
              this.myTimezone = result.timezoneName
            } else {
              this.myTimezone = 'Not Setup'
            }
          }
        })

      this.$store.dispatch('communication/saveTimezone', {
        timezone: this.myTimezone
      })
    }
  },
  async mounted() {
    this.loading = true
    this.visiblePage = 'DeliveryDate'

    this.point =
      this.deliveryDate && this.deliveryDate.length > 0
        ? new Date(this.deliveryDate).toISOString()
        : new Date().toISOString()
    this.pointFormatted = moment(this.point).format('LLL')

    await this.loadHoaTimezone()

    this.loading = false
  },

  watch: {
    step() {
      if (this.deliveryDate) {
        console.debug('watch this.deliveryDate=' + this.deliveryDate)
        this.redirectToFutureDate()
      }
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('communication', [
        'supportMailDelivery',
        'supportEmailDelivery',
        'supportSMSDelivery',
        'isLegalCommunication',
        'mailingTypeID',
        'mailMessage',
        'mailingCommunicationID',
        'deliveryDate',
        'communicationDescription',
        'isColor',
        'isFullDuplex'
      ])
    })
  }
}
</script>
