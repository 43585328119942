/*
  import { escrowStore } from '@/services/Escrow/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await escrowStore.dispatch ('getEscrowList', {
      hoaId
    });
  */

  async getEscrowList({}, { hoaID }) {
    console.log('in getEscrowList...' + hoaID)
    try {
      const results = await kms.get(`/Escrows/Escrow/List?hoaID=${hoaID}`)

      if (isDebug == true) console.debug('getEscrowList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The Escrow list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getListValues({}, { name }) {
    console.log('in getListValues...' + name)
    try {
      const results = await kms.get(`/Support/ListValues/GetValues?name=${name}`)

      if (isDebug == true) console.debug('getListValues log=' + JSON.stringify(results))

      return {
        list: results.results,
        extendedResult: results.extendedResult
      }
    } catch (exception) {
      notifyProblem(`The list values could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: [],
      extendedResult: ''
    }
  },

  async getEscrowById({}, { escrowID }) {
    try {
      console.debug('in getEscrowById...')
      const result = await kms.get(`/Escrows/Escrow/${escrowID}`)

      if (isDebug == true) console.debug('getEscrowById=' + JSON.stringify(result))

      return {
        result: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving this escrow detail.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  async getFileByType({ dispatch }, { params, done }) {
    const path = `/Escrows/Escrow/GetFileByType`
    const result = await kms.get(path, {
      params
    })

    if (this.isDebug == true) console.debug('getFileByType=' + JSON.stringify(result))

    if (dispatch && dispatch != undefined) console.debug(dispatch)

    if (result) {
      console.debug(`Successfully retrieved the document.`)
      done({ details: result })
    } else {
      console.error('There was a problem retrieving the document.')
    }
  },

  async inspectionClose({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in inspectionClose')
    try {
      const results = await kms.post(`/Escrows/Escrow/SetInspectionCompleted`, {
        escrowID: payload.escrowID,
        inspectionCompleteDate: payload.inspectionCompleteDate
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.escrowID > 0) {
        notifyMessage(`Successfully closed this inspection.`)
        done()
      } else {
        notifyError('There was a problem closing this inspection.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem closing this inspection.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const escrowStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
