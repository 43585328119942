<template>
  <div>
    <div v-if="visiblePage === 'Compose'">
      <MessageComposer
        message=""
        :finalizeMessage="finalizeMessage"
        :showPreview="true"
        :showSendTestEmail="true"
        :processPreview="processFinalPreview"
        :processSendTestEmail="processSendTestEmail"
        :composedText="emailMessage"
        :composedSubject="emailSubject"
        :handleImageAdded="handleImageAdded"
        :handleImageRemoved="handleImageRemoved"
        :isReadOnly="isReadOnly"
      />
    </div>
    <div v-else-if="visiblePage === 'FinalPreview'">
      <div class="force-center subtitle pb-5">
        File Preview
      </div>
      <div
        class="pb-5"
        style="text-align: left !important;"
        v-if="typeof (composedSubject === 'string')"
        v-html="composedSubject"
      ></div>
      <div
        class="pb-5"
        style="text-align: left !important;"
        v-if="typeof (composedText === 'string')"
        v-html="composedText"
      ></div>
      <div>
        <b-button type="is-text" @click.prevent="processCompose">Edit Email Message</b-button>
      </div>
      <div class="pt-5">
        <span class="pr-5"
          ><b-button type="is-primary" @click.prevent="processCompose">Previous</b-button></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import MessageComposer from '../keys/MessageComposer'
import { mapState, mapGetters } from 'vuex'
import { notifyMessage, notifyError } from '@/services/notify'
import { mailingCommunicationStore } from '@/services/MailingCommunications/store'
import { KMS_BACKEND } from '@/config'

export default {
  name: 'EmailMessage',

  props: {
    communication: Object,
    isReadOnly: Boolean,
    step: Number
  },

  components: {
    MessageComposer
  },

  data() {
    return {
      visiblePage: 'Compose',
      showPreview: true,
      communicationID: 0,
      composedText: '',
      composedSubject: ''
    }
  },

  methods: {
    async handleImageAdded(file, Editor, cursorLocation) {
      console.debug('in handleImageAdded...' + cursorLocation)

      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      try {
        console.debug('file=' + file)

        if (file !== undefined && file) {
          const payload = {
            communicationID: this.mailingCommunicationID,
            cursorPosition: cursorLocation,
            supportsMail: false,
            supportsEmail: true,
            description: this.communicationDescription
          }

          console.debug('payload addConversationAttachment=' + JSON.stringify(payload))

          await mailingCommunicationStore.dispatch('addCommunicationAttachment', {
            payload,
            done: async ({ detail }) => {
              if (detail) {
                console.debug('addCommunicationAttachment=' + JSON.stringify(detail))

                const payload = {
                  communicationAttachmentID: detail.communicationAttachmentID,
                  file: file
                }

                await mailingCommunicationStore
                  .dispatch('uploadCommunicationAttachmentDocument', {
                    payload: payload
                  })
                  .then(async returnedfile => {
                    if (returnedfile && detail) {
                      console.debug('returnedfile=' + JSON.stringify(returnedfile))

                      Editor.insertEmbed(
                        cursorLocation,
                        'image',
                        `${KMS_BACKEND}/MailingComponents/CommunicationAttachment/GetFilePublic?token=${detail.securityToken}`
                      )
                    }
                  })
              }
            }
          })
        }
      } catch (e) {
        setTimeout(() => loadingComponent.close(), 7000)
        notifyError(e)
      }

      setTimeout(() => loadingComponent.close(), 7000)
    },

    async handleImageRemoved(file) {
      console.debug('in handleImageRemoved...')
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      const flatContents = JSON.stringify(file)
      const flatContentsSplit = flatContents.split('communicationAttachmentID=')
      const communicationAttachmentID = parseInt(
        flatContentsSplit[1] !== undefined && flatContentsSplit[1] ? flatContentsSplit[1] : 0
      )

      console.debug('communicationAttachmentID=' + communicationAttachmentID)

      if (communicationAttachmentID > 0) {
        try {
          await mailingCommunicationStore.dispatch('deleteCommunicationAttachment', {
            communicationAttachmentID,
            done: () => {}
          })
        } catch (e) {
          loadingComponent.close()
          console.debug('There was an issue deleting this communication attachment.' + e)
        }
      }

      loadingComponent.close()
    },

    processFinalPreview(content, subject) {
      console.debug('in processFinalPreview Email...' + content)
      this.$store.dispatch('communication/composedEmailMessage', {
        emailMessage: content
      })

      this.$store.dispatch('communication/saveEmailSubject', {
        emailSubject: subject
      })

      this.composedText = content
      this.composedSubject = subject
      this.visiblePage = 'FinalPreview'
    },

    async processSendTestEmail(content, emailSubject) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      if (emailSubject === undefined || !emailSubject) {
        notifyError('The email subject is required.')
        loadingComponent.close()
        return
      }

      if (content === undefined || !content) {
        notifyError('The email message is required.')
        loadingComponent.close()
        return
      }

      await mailingCommunicationStore.dispatch('sendEmailMessage', {
        hoaID: this.hoaId,
        subject: emailSubject,
        message: content,
        done: async ({ detail }) => {
          console.debug('detail=' + detail)
          if (detail) {
            notifyMessage(`Successfully sent a test email to ${this.authUser.email || ''}`)
          }
        }
      })

      loadingComponent.close()
    },

    processCompose() {
      this.visiblePage = 'Compose'
      this.loadFromState()
    },

    processEditMessage() {},

    async finalizeMessage(content, emailSubject) {
      console.debug('finalizeMessage...')

      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      if (emailSubject === undefined || !emailSubject) {
        notifyError('The email subject is required.')
        loadingComponent.close()
        return
      }

      if (content === undefined || !content) {
        notifyError('The email message is required.')
        loadingComponent.close()
        return
      }

      if (content && content.length > 0) {
        this.$store.dispatch('communication/composedEmailMessage', {
          emailMessage: content
        })
        this.composedText = content

        this.$store.dispatch('communication/saveEmailSubject', {
          emailSubject: emailSubject
        })
        this.composedSubject = emailSubject

        const params = {
          communicationID: this.mailingCommunicationID,
          supportMailDelivery: this.supportMailDelivery,
          supportEmailDelivery: this.supportEmailDelivery,
          supportSMSDelivery: this.supportSMSDelivery,
          supportPushDelivery: this.supportPushDelivery,
          isLegalCommunication: this.isLegalCommunication,
          description: this.communicationDescription,
          mailFullDuplex: null,
          mailColor: null,
          htmlEmailContent: content,
          emailSubject: emailSubject,
          emailPriority: 'normal'
        }

        await mailingCommunicationStore.dispatch('updateCommunicationMessage', {
          params,
          done: async ({ detail }) => {
            if (detail) {
              console.debug('update communication=' + JSON.stringify(detail))

              this.communicationID = detail.communicationID

              notifyMessage('This message has been saved for sending.')
            }
          }
        })

        loadingComponent.close()
      }
    },

    loadFromState() {
      setTimeout(() => {
        this.composedText = this.emailMessage || ''
        this.composedSubject = this.emailSubject || ''
        console.debug('loadFromState...' + this.composedText + ', subject=' + this.composedSubject)
      }, 4000)
    }
  },

  mounted() {
    this.loadFromState()
  },

  computed: {
    ...mapState({
      ...mapGetters('user', ['authUser']),
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('communication', [
        'supportMailDelivery',
        'supportEmailDelivery',
        'supportSMSDelivery',
        'isLegalCommunication',
        'mailingTypeID',
        'emailMessage',
        'emailSubject',
        'mailingCommunicationID',
        'communicationDescription',
        'isColor',
        'isFullDuplex'
      ])
    })
  }
}
</script>
