import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  statusFilter: 'pending',
  toggle: false,
  selectedEscrowID: 0,
  escrowPropertiesList: [],
  escrowExtended: '',
  hrefMailto: '',
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'lotNumber',
      label: 'Lot Number',
      aria: 'Lot Number',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'address',
      label: 'Property Address',
      aria: 'Property Address',
      width: '40%',
      sortable: true,
      searchable: true
    },
    {
      field: 'buyerName',
      label: 'Buyer Name',
      aria: 'Buyer Name',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'closingDate',
      label: 'Closing',
      aria: 'Closing',
      date: true,
      width: '15%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
