<template>
  <div>
    <div v-if="visiblePage === 'Target'">
      <div class="force-center subtitle pb-5 pt-5">
        Would you like to send your message to all homeowners?
      </div>
      <div class="subtitle force-center">
        <button
          type="button"
          @click.prevent="processAllHomeowners"
          class="button is-link is-light"
          :disabled="isReadOnly === true"
        >
          YES
        </button>
        <span class="pl-2 pr-2" style="padding-top: 10px; vertical-align: middle !important;"
          >- or -</span
        >
        <button
          type="button"
          @click.prevent="redirectToSelectedOwners"
          class="button is-link is-light"
          :disabled="isReadOnly === true"
        >
          NO
        </button>
      </div>
    </div>
    <div v-else-if="visiblePage === 'Search'" class="force-center subtitle pb-5">
      <div class="pb-5">
        <span class="force-center subtitle"
          >Search for homeowners to target for this communication</span
        >
      </div>
      <div class="pb-5 pt-5 force-left columns">
        <b-field label="Owner">
          <span class="column is-12"
            ><b-autocomplete
              :disabled="isReadOnly === true"
              vr-owner-select
              v-model="query"
              :data="data"
              :loading="isFiltering"
              :open-on-focus="true"
              :keep-first="true"
              field="label"
              icon="magnify"
              placeholder="e.g. First Name or Last Name or Address"
              @typing="filterList"
              @select="optionSelected"
            >
              <template #empty>No results found</template>
            </b-autocomplete>
          </span>
          <span class="column is-1">
            <b-button
              v-on:keyup.enter="addHomeowner"
              :disabled="isReadOnly === true"
              type="is-primary is-small"
              @click.prevent="addHomeowner"
              >Add Homeowner</b-button
            ></span
          >
        </b-field>
      </div>
      <div class="column is-12 pl-3">
        <label class="label">Selected Homeowners</label>
        <div>
          <div
            :style="
              `
              display: inline;
              `
            "
            class="columns"
          >
            <div
              v-if="
                recipientsSelected !== undefined &&
                  recipientsSelected &&
                  recipientsSelected.length === 0
              "
            >
              <span style="font-style:italic">No homeowners added</span>
            </div>
            <div
              class="pt-3"
              v-for="(recipient, index) in recipientsSelected"
              :key="index"
              :style="{
                verticalAlign: 'top',
                marginLeft: '0px'
              }"
            >
              <b-taglist attached v-if="index % 2 === 0">
                <b-tag
                  type="is-light"
                  style="background-color: white !important;"
                  closable
                  @close="eraseRecipient({ recipient })"
                  >{{ JSON.stringify(recipient) }}</b-tag
                >
                <b-tag
                  v-if="recipientsSelected[index + 1]"
                  type="is-light"
                  style="background-color: white !important;"
                  closable
                  @close="eraseRecipient2(recipientsSelected[index + 1])"
                  >{{ JSON.stringify(recipientsSelected[index + 1]) }}</b-tag
                >
              </b-taglist>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-5">
        <span class="pr-5"
          ><b-button type="is-primary is-small" @click.prevent="redirectToTarget"
            >Previous</b-button
          ></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get'
import { getOwnersList } from '@/services/Roster/Unit/sequences/getOwnersList'
import { mapState, mapGetters } from 'vuex'
import { notifyMessage, notifyError } from '@/services/notify'
import { mailingCommunicationStore } from '@/services/MailingCommunications/store'

export default {
  name: 'TargetAudience',

  props: {
    communication: Object,
    isReadOnly: Boolean,
    step: Number
  },

  data() {
    return {
      visiblePage: 'Target',
      loading: false,
      name: '',
      showPreview: false,
      data: [],
      fullList: [],
      recipientsSelected: [],
      ownerIdsSelected: [],
      isFiltering: false,
      selected: null,
      query: null,
      formData: {
        address: ''
      }
    }
  },
  methods: {
    keydownHandler(e) {
      if (e.keyCode === 13) {
        console.debug('selected===' + this.selected)
        //this.addHomeowner()
      }
    },
    redirectToTarget() {
      this.visiblePage = 'Target'
    },
    redirectToSelectedOwners() {
      this.visiblePage = 'Search'
    },

    async eraseRecipient({ recipient }) {
      if (this.isReadOnly === false) {
        const recipientsSelected = this.recipientsSelected
        let tempRecipients = []
        this.ownerIdsSelected = []

        if (recipientsSelected !== undefined && recipientsSelected) {
          for (let a = 0; a < recipientsSelected.length; a++) {
            const tempRecipient = recipientsSelected[a]

            console.debug(
              JSON.stringify(recipient) + ' - recips - ' + JSON.stringify(tempRecipient)
            )

            if (tempRecipient && tempRecipient !== recipient) {
              tempRecipients.push(tempRecipient)
              this.addSelectedOwnerValues(tempRecipient)
            }
          }
        }

        this.recipientsSelected = tempRecipients

        await this.processSelectHomeowners()
      }
    },

    async eraseRecipient2(recipient) {
      if (this.isReadOnly === false) {
        const recipientsSelected = this.recipientsSelected
        let tempRecipients = []
        this.ownerIdsSelected = []

        if (recipientsSelected !== undefined && recipientsSelected) {
          for (let a = 0; a < recipientsSelected.length; a++) {
            const tempRecipient = recipientsSelected[a]

            console.debug(
              JSON.stringify(recipient) + ' - recips - ' + JSON.stringify(tempRecipient)
            )

            if (tempRecipient && tempRecipient !== recipient) {
              tempRecipients.push(tempRecipient)
              this.addSelectedOwnerValues(tempRecipient)
            }
          }
        }

        this.recipientsSelected = tempRecipients

        await this.processSelectHomeowners()
      }
    },

    async addHomeowner() {
      console.debug('in addHomeowner...' + JSON.stringify(this.recipientsSelected))
      if (this.query !== undefined && this.query && this.query !== '') {
        const isPresent = this.recipientsSelected.filter(f => f === this.query)
        console.debug('this.query=' + JSON.stringify(this.query))
        if (isPresent !== undefined && isPresent[0] !== undefined && isPresent[0]) {
          console.debug('addHomeowners=' + JSON.stringify(this.recipientsSelected))
        } else {
          this.recipientsSelected.push(this.query)
          this.addSelectedOwnerValues(this.query)
          this.processSelectHomeowners()
        }
      }

      console.debug('this.recipientsSelected=' + JSON.stringify(this.recipientsSelected))
    },

    addSelectedOwnerValues(value) {
      const owner = this.fullList.filter(owner => {
        try {
          if (owner.label.toLowerCase().includes(value.toLowerCase())) {
            return owner
          }
        } catch (x) {
          console.error(x)
        }
      })

      if (
        owner[0] !== undefined &&
        owner[0].value !== undefined &&
        owner[0].value &&
        owner[0].value > 0
      ) {
        this.ownerIdsSelected.push(owner[0].value)
        console.debug('ownerIdsSelected=' + JSON.stringify(this.ownerIdsSelected))

        this.$store.dispatch('communication/saveDeliveryOwnerIDs', {
          selectedDeliveryOwnerIDs: this.ownerIdsSelected
        })
      }
    },

    async processAllHomeowners() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      this.$store.dispatch('communication/saveDeliveryOwners', {
        selectedDeliveryOwners: []
      })

      this.ownerIdsSelected = []

      try {
        const params = {
          communicationID: this.mailingCommunicationID,
          supportMailDelivery: this.supportMailDelivery,
          supportEmailDelivery: this.supportEmailDelivery,
          supportSMSDelivery: this.supportSMSDelivery,
          supportPushDelivery: this.supportPushDelivery,
          isLegalCommunication: this.isLegalCommunication,
          description: this.communicationDescription,
          mailFullDuplex: null,
          mailColor: null,
          deliveryAudience: 'all-owners'
        }

        await mailingCommunicationStore.dispatch('updateCommunicationMessage', {
          params,
          done: async ({ detail }) => {
            console.debug('update communication=' + JSON.stringify(detail))
            if (detail) {
              notifyMessage('Your target audience has been set to all homeowners.')
            }
          }
        })

        loadingComponent.close()
      } catch (e) {
        notifyError('There was a problem setting your target audience to all homeowners.')
      }

      loadingComponent.close()
    },

    async processSelectHomeowners() {
      const params = {
        communicationID: this.mailingCommunicationID,
        supportMailDelivery: this.supportMailDelivery,
        supportEmailDelivery: this.supportEmailDelivery,
        supportSMSDelivery: this.supportSMSDelivery,
        supportPushDelivery: this.supportPushDelivery,
        isLegalCommunication: this.isLegalCommunication,
        mailFullDuplex: null,
        mailColor: null,
        deliveryAudience: 'selected-owners'
      }

      this.$store.dispatch('communication/saveDeliveryOwners', {
        selectedDeliveryOwners: this.recipientsSelected
      })

      await mailingCommunicationStore.dispatch('updateCommunicationMessage', {
        params,
        done: async ({ detail }) => {
          if (detail) {
            console.debug('update communication=' + JSON.stringify(detail))

            //Save to CommunicationDeliveryOwners table
            const payload = {
              communicationID: detail.communicationID,
              ownerIDs: this.ownerIdsSelected
            }

            console.debug('ownerIDs=...................' + JSON.stringify(payload))

            await mailingCommunicationStore.dispatch('addDeliveryOwners', {
              payload,
              done: async ({ detail }) => {
                if (detail) {
                  console.debug('update communication=' + JSON.stringify(detail))

                  this.communicationID = detail.communicationID

                  notifyMessage('Your target audience was saved for your selected homeowners.')
                }
              }
            })
          }
        }
      })
    },

    optionSelected(option) {
      this.selected = option
      this.formData.address = ''
      this.addHomeowner()
    },

    filterList: function(name) {
      this.formData.address = ''

      // String update
      if (this.name !== name) {
        this.name = name
        this.data = []
      } else {
        // no update occurred
        return
      }

      this.isFiltering = true

      if (this.name === '') {
        this.data = this.fullList
      }

      this.data = this.fullList.filter(owner => {
        try {
          if (owner.label.toLowerCase().includes(this.name.toLowerCase())) {
            return true
          }
        } catch (x) {
          console.error(x)
        }

        return false
      })

      this.isFiltering = false
    }
  },
  async mounted() {
    this.loading = true
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const { list } = await getOwnersList({
      hoaID
    })

    this.data = list
    this.fullList = list

    console.debug('fullList=' + JSON.stringify(this.fullList))

    this.ownerIdsSelected = this.selectedDeliveryOwnerIDs
    if (this.fullList && this.ownerIdsSelected && this.ownerIdsSelected.length > 0) {
      Array.from(this.ownerIdsSelected).forEach(ownerID => {
        if (ownerID) {
          const tempOwner = this.fullList.filter(owner => {
            try {
              if (owner.value === ownerID) {
                return owner.label
              }
            } catch (x) {
              console.error(x)
            }
          })

          if (
            this.recipientsSelected !== undefined &&
            this.recipientsSelected &&
            tempOwner[0] !== undefined &&
            tempOwner[0] &&
            tempOwner[0].label
          ) {
            this.recipientsSelected.push(tempOwner[0].label)
          }
        }
      })

      if (
        this.recipientsSelected !== undefined &&
        this.recipientsSelected &&
        this.recipientsSelected.length > 0
      ) {
        this.$store
          .dispatch('communication/saveDeliveryOwners', {
            selectedDeliveryOwners: this.recipientsSelected
          })
          .then(() => {
            this.$store.dispatch('communication/saveDeliveryOwnerIDs', {
              selectedDeliveryOwnerIDs: this.ownerIdsSelected
            })
          })
      }
    }

    this.loading = false
  },

  watch: {
    step() {
      if (this.selectedDeliveryOwners && this.selectedDeliveryOwners.length > 0) {
        this.redirectToSelectedOwners()
      }
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('communication', [
        'supportMailDelivery',
        'supportEmailDelivery',
        'supportSMSDelivery',
        'isLegalCommunication',
        'mailingTypeID',
        'mailMessage',
        'mailingCommunicationID',
        'selectedDeliveryOwnerIDs',
        'selectedDeliveryOwners',
        'communicationDescription',
        'isColor',
        'isFullDuplex'
      ])
    })
  }
}
</script>
