import { mailingCommunicationStore } from '@/services/MailingCommunications/store'
import $ from 'jquery'
import uniqBy from 'lodash/uniqBy'

export const methods = {
  async reload() {
    this.loading = true

    await this.loadHardCodedDropDowns()
    await this.loadMailingList()
    await this.loadMailingTypeList()

    if (this.mailingTypeList[1] !== undefined && this.mailingTypeList[1]) {
      this.formData.mailingTypeID = this.mailingTypeList[1].value
      this.$store.dispatch('communication/saveMailingTypeID', {
        mailingTypeID: this.formData.mailingTypeID
      })
    }

    this.loading = false
  },

  async addCommunication() {
    if (this.mailingTypeList[1] !== undefined && this.mailingTypeList[1]) {
      this.formData.mailingTypeID = this.mailingTypeList[1].value
      this.$store.dispatch('communication/saveMailingTypeID', {
        mailingTypeID: this.formData.mailingTypeID
      })
    }

    if (this.communicationID <= 0 && !this.communication) {
      const payload = {
        hoaID: this.hoaId,
        compositionMethod: 'html',
        htmlContent: '',
        htmlEmailContent: '',
        htmlSmsContent: '',
        documentID: null,
        mailingTypeID: this.mailingTypeID,
        supportMailDelivery: this.supportMailDelivery,
        supportEmailDelivery: this.supportEmailDelivery,
        supportSMSDelivery: this.supportSMSDelivery,
        supportPushDelivery: this.supportPushDelivery,
        isLegalCommunication: this.isLegalCommunication,
        emailSubject: '',
        emailPriority: '',
        smsSubject: '',
        pushSubject: '',
        deliveryAudience: '',
        description: this.formData.description || 'In Progress...'
      }

      await mailingCommunicationStore.dispatch('addCommunication', {
        payload,
        done: async ({ detail }) => {
          if (detail) {
            console.debug('add communication=' + JSON.stringify(detail))

            this.communicationID = detail.communicationID

            this.$store.dispatch('communication/saveSessionCommunicationID', {
              mailingCommunicationID: this.communicationID
            })
          }
        }
      })
    }
  },

  closeCommunication() {
    this.$emit('update:toggle', (this.toggle = false))
    $('#clickclose').click()
  },

  prevStep() {},

  sendRequest() {},

  async loadHardCodedDropDowns() {
    await mailingCommunicationStore.dispatch('getCompositionMethodList').then(({ list }) => {
      if (list) {
        this.compositionMethodList = list
      }
    })

    await mailingCommunicationStore.dispatch('getEmailPriorityList').then(({ list }) => {
      if (list) {
        this.emailPriorityList = list
      }
    })

    await mailingCommunicationStore.dispatch('getDeliveryAudienceList').then(({ list }) => {
      if (list) {
        this.deliveryAudienceList = list
      }
    })
  },

  async loadMailingList() {
    await mailingCommunicationStore
      .dispatch('getMailingList', { hoaID: this.hoaId })
      .then(({ list }) => {
        if (list) {
          this.mailingListUnfiltered = uniqBy(list, 'label')
          this.mailingList = this.mailingListUnfiltered
        }
      })
  },

  async loadMailingTypeList() {
    await mailingCommunicationStore.dispatch('getMailingTypeList').then(({ list }) => {
      if (list) {
        this.mailingTypeList = list
      }
    })
  },

  changeMailingsByMailType() {
    console.debug('in changeMailingsByMailType...' + this.formData.mailingTypeID)
    this.mailingList = this.mailingListUnfiltered.filter(
      f => f.mailingTypeID === this.formData.mailingTypeID
    )
  },

  open() {
    this.isOpen = true
    this.loading = false
  },

  async processSubmit() {},

  closeModal() {
    this.$emit('update:toggle', (this.toggle = false))
    $('#clickclose').click()
  }
}
