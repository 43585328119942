<template>
  <PageContentLayoutOnly role="region">
    <div class="title pl-2">{{ $t('mailingCommunication.title') }}</div>
    <div class="pb-5">
      <b-button @click.prevent="addCommunication" size="is-small" type="is-primary" rounded>
        Add Communication
      </b-button>
    </div>

    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
      headerColor="#FFF"
      condensed
    >
      <template v-slot:header>
        <div :style="{ width: '100%', height: '60px' }">
          <b-button
            type="is-ghost"
            @click="filters.show = !filters.show"
            :style="{ float: 'right', background: 'none', border: 0 }"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>
    <Modal :toggle.sync="toggle">
      <CommunicationModal
        :toggle="toggle"
        :communication="selectedCommunication"
        :isReadOnly="isReadOnly"
      ></CommunicationModal>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import { methods } from './keys/methods'
import { data } from './keys/data'
import Modal from '@/components/Modal'
import CommunicationModal from '@/pages/Shared/mailingCommunications/components/communicationModal'

export default {
  name: 'CommunicationMailingComponent',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    Modal,
    CommunicationModal
  },

  data,

  methods,

  computed: {
    ...mapState({
      unit: state => state.hoa.unit,
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },

  watch: {
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { mailingCommunication: { title: 'Communications' } }
    }
  }
}
</script>

<style></style>
