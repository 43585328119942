<template>
  <div>
    <div v-if="visiblePage === 'YesNo'">
      <div class="force-center pb-5">
        Is your message saved to a file you need to upload?
      </div>
      <div
        class="subtitle force-center"
        v-if="mailingTypeID !== undefined && mailingTypeID && mailingTypeID > 0"
      >
        <button
          type="button"
          :disabled="isReadOnly === true"
          @click.prevent="processUploadAdd"
          class="button is-link is-light"
        >
          YES
        </button>
        <span class="pl-2 pr-2" style="padding-top: 10px; vertical-align: middle !important;"
          >- or -</span
        >
        <button
          :disabled="isReadOnly === true"
          type="button"
          @click.prevent="processComposeAdd"
          class="button is-link is-light"
        >
          NO
        </button>
      </div>
      <div v-else class="redcurrency pb-3">
        Please select a Delivery Mailing Type to enable file uploads
      </div>
      <div>
        *Please note:* For all uploaded communications mailed to the homeowner; an additional page
        is added to display the homeowners address on the letter.
      </div>
    </div>
    <div v-else-if="visiblePage === 'FileUpload'">
      <div class="box is-8">
        <section>
          <div class="force-center subtitle pb-5">
            Select File to Upload
          </div>
          <div>
            <!-- Show the Upload Form -->
            <b-field class="file">
              <b-upload v-model="file" drag-drop accept="application/pdf" class="rectangle">
                <section class="section">
                  <div class="">
                    <div>
                      <img
                        class="Fill-1-Copy-11"
                        src="@/assets/images/fill-1-copy-11.svg"
                        alt="Upload"
                      />
                    </div>
                    <div v-if="file">
                      {{ file.name }}
                    </div>
                    <div v-else>
                      <div class="Drag-Drop-your-fil">
                        <div class="text-style-1">Drag & Drop</div>
                        <div>
                          *Please note:* We only support PDF file uploads at this time and the file
                          must be less than 5 pages.
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </b-upload>
            </b-field>
          </div>
          <div class="pt-5">
            <span class="pr-5"
              ><b-button type="is-primary" @click.prevent="processYesNo">Previous</b-button></span
            >
            <span class="pr-5"
              ><b-button type="is-primary" :disabled="!file || isReadOnly" @click="submitFile"
                >Upload</b-button
              ></span
            >

            <span><b-button type="is-primary" @click.prevent="processPreview">Next</b-button></span>
          </div>
        </section>
      </div>
    </div>
    <div v-else-if="visiblePage === 'PreviewUpload'">
      <div class="force-center subtitle pb-5">
        Preview File
      </div>
      <div id="viewpdf" v-if="base64pdf" class="has-text-centered">
        <div>
          <div>
            <div v-dragscroll="true" :class="['doc-viewer', { 'zoom-active': scale > 0.9 }]">
              <pdf
                style="display: none;"
                :src="base64pdf"
                @num-pages="pageCount = new Array($event)"
              />
              <!-- Actual document -->
              <pdf
                v-for="(page, index) in pageCount"
                :key="index"
                :src="`${base64pdf}`"
                :page="index + 1"
                :scale="scale"
                :resize="true"
                :style="{ margin: 'auto', width: `${100 * scale}%` }"
              />
            </div>
          </div>
          <div class="doc-controls has-text-center">
            <b-field position="is-centered">
              <div class="control">
                <b-button @click.prevent="zoom(-1)">-</b-button>
              </div>
              <div class="control">
                <b-button @click.prevent="zoom(1)">+</b-button>
              </div>
            </b-field>
          </div>
        </div>
      </div>
      <div class="pt-5">
        <span class="pr-5"
          ><b-button type="is-primary" @click.prevent="processUpload">Previous</b-button></span
        >
      </div>
    </div>
    <div v-else-if="visiblePage === 'Compose'">
      <div>
        <MessageComposer
          message="*Please note:* Depending on the amount of content added, your communication may span multiple pages."
          :finalizeMessage="finalizeMessage"
          :showSendTestEmail="false"
          :showPreview="true"
          :showMailOptions="true"
          :processPreview="processFinalPreview"
          :composedText="composedText"
          :composedIsColor="isColor"
          :composedIsFullDuplex="isFullDuplex"
          :handleImageAdded="handleImageAdded"
          :handleImageRemoved="handleImageRemoved"
          :isReadOnly="isReadOnly"
        />
      </div>
      <div class="pt-5">
        <span class="pr-5"
          ><b-button type="is-primary" @click.prevent="processYesNo">Previous</b-button></span
        >
      </div>
    </div>
    <div v-else-if="visiblePage === 'FinalPreview'">
      <div class="force-center subtitle pb-5">
        File Preview
      </div>
      <div
        class="pb-5"
        style="text-align: left !important;"
        v-if="typeof (composedText === 'string')"
        v-html="composedText"
      ></div>
      <div>
        <b-button type="is-text" @click.prevent="processComposeEdit">Edit Message</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import { dragscroll } from 'vue-dragscroll'
import MessageComposer from '../keys/MessageComposer'
import { mapState, mapGetters } from 'vuex'
import { mailingCommunicationStore } from '@/services/MailingCommunications/store'
import { notifyMessage, notifyError } from '@/services/notify'
import { KMS_BACKEND } from '@/config'

export default {
  name: 'ComposeMessage',
  props: {
    communication: Object,
    isReadOnly: Boolean,
    step: Number
  },
  data() {
    return {
      visiblePage: 'YesNo',
      buttonText: 'Add',
      loading: false,
      file: null,
      base64pdf: '',
      pageCount: [],
      scale: 1,
      imageContent: null,
      documentName: '',
      attachment: false,
      communicationDocumentID: 0,
      communicationID: 0,
      composedText: '',
      documentFile: null
    }
  },
  components: {
    pdf,
    MessageComposer
  },
  directives: { dragscroll },
  methods: {
    async handleImageAdded(file, Editor, cursorLocation) {
      console.debug('in handleImageAdded...' + cursorLocation)

      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      try {
        console.debug('file=' + file)

        if (file !== undefined && file) {
          const payload = {
            communicationID: this.communicationID,
            cursorPosition: cursorLocation,
            supportsMail: true,
            supportsEmail: false,
            description: this.communicationDescription
          }

          console.debug('payload addConversationAttachment=' + JSON.stringify(payload))

          await mailingCommunicationStore.dispatch('addCommunicationAttachment', {
            payload,
            done: async ({ detail }) => {
              if (detail) {
                console.debug('addCommunicationAttachment=' + JSON.stringify(detail))

                const payload = {
                  communicationAttachmentID: detail.communicationAttachmentID,
                  file: file
                }

                await mailingCommunicationStore
                  .dispatch('uploadCommunicationAttachmentDocument', {
                    payload: payload
                  })
                  .then(async returnedfile => {
                    if (returnedfile && detail) {
                      console.debug('returnedfile=' + JSON.stringify(returnedfile))

                      Editor.insertEmbed(
                        cursorLocation,
                        'image',
                        `${KMS_BACKEND}/MailingComponents/CommunicationAttachment/GetFilePublic?token=${detail.securityToken}`
                      )
                    }
                  })
              }
            }
          })
        }
      } catch (e) {
        setTimeout(() => loadingComponent.close(), 7000)
        notifyError(e)
      }

      setTimeout(() => loadingComponent.close(), 7000)
    },

    async handleImageRemoved(file) {
      console.debug('in handleImageRemoved...')
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      const flatContents = JSON.stringify(file)
      const flatContentsSplit = flatContents.split('communicationAttachmentID=')
      const communicationAttachmentID = parseInt(
        flatContentsSplit[1] !== undefined && flatContentsSplit[1] ? flatContentsSplit[1] : 0
      )

      console.debug('communicationAttachmentID=' + communicationAttachmentID)

      if (communicationAttachmentID > 0) {
        try {
          await mailingCommunicationStore.dispatch('deleteCommunicationAttachment', {
            communicationAttachmentID,
            done: () => {}
          })
        } catch (e) {
          loadingComponent.close()
          console.debug('There was an issue deleting this communication attachment.' + e)
        }
      }

      loadingComponent.close()
    },

    async getComposition() {
      this.communicationID = this.mailingCommunicationID
    },

    async processUploadAdd() {
      if (this.communicationID <= 0) {
        await this.getComposition()
      }

      this.processUpload()
    },

    async processComposeAdd() {
      console.debug('processComposeAdd()...' + this.communicationID)
      if (this.communicationID <= 0) {
        await this.getComposition()
      }

      this.processCompose()

      this.$store.dispatch('communication/selectedUploadFile', {
        uploadFile: null
      })
      this.$store.dispatch('communication/savePdfFileName', {
        pdfFileName: ''
      })
    },

    processUpload() {
      console.debug('in processYes....')
      this.visiblePage = 'FileUpload'
      console.debug('visiblePage=' + this.visiblePage)
    },

    async submitFile() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      console.debug('in submitFile...')
      this.$store.dispatch('communication/selectedUploadFile', {
        uploadFile: this.file
      })
      this.$store.dispatch('communication/savePdfFileName', {
        pdfFileName: this.file.name
      })

      console.debug('this.file=' + this.file)

      if (this.file !== undefined && this.file) {
        if (this.file && this.communicationID > 0) {
          const payload = {
            file: this.file,
            communicationID: this.communicationID
          }

          console.debug('communicationID=' + this.communicationID)
          await mailingCommunicationStore
            .dispatch('uploadCommunicationDocument', {
              payload
            })
            .then(async ({ returnedfile }) => {
              if (returnedfile) {
                this.documentID = returnedfile.documentID

                const params = {
                  communicationID: this.mailingCommunicationID,
                  supportMailDelivery: this.supportMailDelivery,
                  supportSMSDelivery:
                    this.isLegalCommunication === false ? this.supportSMSDelivery : false,
                  supportEmailDelivery:
                    this.isLegalCommunication === false ? this.supportEmailDelivery : false,
                  isLegalCommunication: this.isLegalCommunication,
                  htmlContent: '',
                  documentID: this.documentID,
                  compositionMethod: 'pdf-document',
                  description: this.communicationDescription
                }

                await mailingCommunicationStore.dispatch('updateCommunicationMessage', {
                  params,
                  done: async ({ detail }) => {
                    if (detail) {
                      console.debug('update communication=' + JSON.stringify(detail))

                      this.communicationID = detail.communicationID

                      notifyMessage('The document has been successfully saved.')
                    }
                  }
                })
              }
            })
        }
        loadingComponent.close()
      }
    },

    async getDocument(isEdit) {
      console.debug('getDocument___________________________________________' + isEdit)

      try {
        const params = {
          communicationID: this.communicationID,
          asBase64: true
        }

        await mailingCommunicationStore
          .dispatch('getCommunicationDocumentGetFile', {
            params: params
          })
          .then(({ returned }) => {
            if (returned) {
              this.documentFile = returned
            }

            if (this.isDebug == true)
              console.debug(
                ' - params' + JSON.stringify(params) + ' - this.documentFile=' + returned
              )
            if (this.documentFile && this.documentFile != undefined) {
              if (this.isDebug == true)
                console.debug('image or pdf............this.searchTerm:' + this.documentFile)

              this.base64pdf = this.documentFile
              this.imageContent = null

              if (isEdit && isEdit === true) {
                console.debug('converting base64 to file object..............')
                if (this.documentFile) {
                  console.debug('converting base64 to file object..............')
                  const mimeType = (this.documentFile.match(/^data:([^;]+);/) || '')[1]
                  const returnedFile = this.dataURLtoFile(
                    this.documentFile,
                    `request-file.${mimeType.replace('image/', '')}`
                  )

                  if (returnedFile) {
                    console.debug('converting base64 to file object..............')
                    this.file = returnedFile
                    this.$store
                      .dispatch('communication/selectedUploadFile', {
                        uploadFile: this.file
                      })
                      .then(() => {
                        this.$store
                          .dispatch('communication/savePdfFileName', {
                            pdfFileName: this.file.name
                          })
                          .then(() => {
                            console.debug('converting base64 to file object..............')
                            this.visiblePage = 'PreviewUpload'
                          })
                      })
                  }
                }
              }
            }
          })
      } catch (e) {
        if (this.isDebug == true) console.debug(e)
      }
    },

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },

    async processPreview() {
      console.debug('in processNext...')
      await this.getDocument(false)
      this.visiblePage = 'PreviewUpload'
    },

    processYesNo() {
      console.debug('in processPrevious...')
      this.visiblePage = 'YesNo'
    },

    processCompose() {
      this.visiblePage = 'Compose'
    },

    processFinalPreview(content, subject, isDuplexParam, isColorParam) {
      console.debug('in processFinalPreview...' + content)
      this.$store.dispatch('communication/composedMailMessage', {
        mailMessage: content
      })
      this.$store.dispatch('communication/saveIsFullDuplex', {
        isFullDuplex: isDuplexParam
      })

      this.$store.dispatch('communication/saveIsColor', {
        isColor: isColorParam
      })

      this.composedText = content
      this.visiblePage = 'FinalPreview'
    },

    processComposeEdit() {
      this.processCompose()
    },

    async finalizeMessage(content, subject, isDuplexParam, isColorParam) {
      console.debug(
        'finalizeMessage postal...' +
          content +
          ', ' +
          subject +
          ', ' +
          isDuplexParam +
          ', ' +
          isColorParam
      )

      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      if (content === undefined || !content || content.length === 0) {
        notifyError('The message is required.')
        return
      }

      this.$store.dispatch('communication/selectedUploadFile', {
        uploadFile: null
      })

      this.$store.dispatch('communication/savePdfFileName', {
        pdfFileName: ''
      })

      this.$store.dispatch('communication/saveIsFullDuplex', {
        isFullDuplex: isDuplexParam
      })

      this.$store.dispatch('communication/saveIsColor', {
        isColor: isColorParam
      })

      console.debug('isFullDuplex=' + this.isFullDuplex)
      console.debug('isColor=' + this.isColor)

      try {
        if (content && content.length > 0) {
          this.$store.dispatch('communication/composedMailMessage', {
            mailMessage: content
          })
          this.composedText = content

          const params = {
            communicationID: this.communicationID,
            supportMailDelivery: this.supportMailDelivery,
            supportEmailDelivery:
              this.isLegalCommunication === false ? this.supportEmailDelivery : false,
            supportSMSDelivery:
              this.isLegalCommunication === false ? this.supportSMSDelivery : false,
            isLegalCommunication: this.isLegalCommunication,
            htmlContent: content,
            mailFullDuplex: isDuplexParam,
            mailColor: isColorParam,
            documentID: null,
            compositionMethod: 'html',
            description: this.communicationDescription
          }

          console.debug('mail params=' + JSON.stringify(params))

          await mailingCommunicationStore.dispatch('updateCommunicationMessage', {
            params,
            done: async ({ detail }) => {
              if (detail) {
                console.debug('update communication=' + JSON.stringify(detail))

                this.communicationID = detail.communicationID

                notifyMessage('This message has been saved for sending.')
              }
            }
          })
        }
      } catch (error) {
        console.debug('error=' + error.message)
        loadingComponent.close()
      }

      loadingComponent.close()
    },

    zoom(direction) {
      const amt = Math.sqrt(2)
      if (direction > 0) {
        this.scale *= amt
      } else {
        this.scale /= amt
      }

      // constrain to min/max
      this.scale = Math.max(0.5, Math.min(4, this.scale))
    }
  },

  mounted() {
    this.composedText = this.mailMessage
  },

  watch: {
    step() {
      this.composedText = this.mailMessage

      if (
        this.communication !== undefined &&
        this.communication &&
        this.communication.communicationID !== undefined &&
        this.communication.communicationID
      ) {
        if (
          this.communication.compositionMethod &&
          this.communication.compositionMethod === 'pdf-document'
        ) {
          this.communicationID = this.mailingCommunicationID
          this.getDocument(true)
        }
      }

      if (this.composedText && this.composedText.length > 0) {
        this.processComposeAdd()
      }
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('communication', [
        'supportMailDelivery',
        'supportEmailDelivery',
        'supportSMSDelivery',
        'isLegalCommunication',
        'mailingTypeID',
        'mailMessage',
        'mailingCommunicationID',
        'communicationDescription',
        'isFullDuplex',
        'isColor'
      ])
    })
  }
}
</script>
<style lang="scss">
#composeMessageModal {
  .modal-h-full {
    left: 50%;
    transform: translate(-50%);
  }

  .doc-viewer {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 130%;
    min-height: 40vh;
    max-height: calc(100vh - 320px);
    border: 1px solid #dbdbdb;

    &.zoom-active {
      cursor: grab;
    }
  }

  .doc-controls {
    margin-top: -1em;
  }
}

#request-image-upload {
  .upload .upload-draggable {
    border: none 0;
    border-radius: 0;
    width: 100%;
    padding: 0;
  }

  .modal-close,
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    margin: 5px 5px 0 0;
  }
}

.uploader {
  .rectangle {
    width: 244px;
    height: 137px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0 0px;
    border-radius: 4px;
  }
  .Drag-Drop-your-fil {
    width: 192px;
    height: 34px;
    margin: 7px 0 8px 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #838383;
  }

  .Drag-Drop-your-fil .text-style-1 {
    font-weight: bold;
  }

  img.Fill-1-Copy-11 {
    width: 24px;
    height: 30px;
    margin: 0 85px 7px 83px;
    object-fit: contain;
  }
}
</style>
