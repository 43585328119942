<template>
  <PageContentLayoutOnly headerColor="#fafafa">
    <div itemdetails class="notification headerColor" v-if="formData">
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="BuyerName"
                      label="Buyer Name"
                      type="text"
                      vid="BuyerName"
                      placeholder="Buyer Name"
                      spellcheck="true"
                      aria-label="Buyer Name"
                      class="is-small"
                      v-model="formData.buyerName"
                      :disabled="true"
                    />
                    <br />
                    <valid-input
                      style="width:50px !important;"
                      name="BuyerPhone"
                      label="Buyer Phone"
                      type="text"
                      vid="BuyerPhone"
                      placeholder="Buyer Phone"
                      spellcheck="true"
                      aria-label="Buyer Phone"
                      class="is-small"
                      v-model="formData.buyerPhone"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="Address"
                      label="Address"
                      type="textarea"
                      vid="Address"
                      placeholder="Address"
                      spellcheck="true"
                      aria-label="Address"
                      class="is-small"
                      v-model="formData.address"
                      :disabled="true"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="BuyerEmail1"
                      label="Buyer Email 1"
                      type="text"
                      vid="BuyerEmail1"
                      placeholder="Buyer Email 1"
                      spellcheck="true"
                      aria-label="Buyer Email 1"
                      class="is-small"
                      v-model="formData.buyerEmail1"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="EscrowType"
                      label="Escrow Type"
                      type="text"
                      vid="EscrowType"
                      placeholder="Escrow Type"
                      spellcheck="true"
                      aria-label="Escrow Type"
                      class="is-small"
                      v-model="formData.escrowType"
                      :disabled="true"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="BuyerEmail2"
                      label="Buyer Email 2"
                      type="text"
                      vid="BuyerEmail2"
                      placeholder="Buyer Email 2"
                      spellcheck="true"
                      aria-label="Buyer Email 2"
                      class="is-small"
                      v-model="formData.buyerEmail2"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="EscrowNumber"
                      label="Escrow Number"
                      type="text"
                      vid="EscrowNumber"
                      placeholder="Escrow Number"
                      spellcheck="true"
                      aria-label="Escrow Number"
                      class="is-small"
                      v-model="formData.escrowNumber"
                      :disabled="true"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="BuyerEmail3"
                      label="Buyer Email 3"
                      type="text"
                      vid="BuyerEmail3"
                      placeholder="Buyer Email 3"
                      spellcheck="true"
                      aria-label="Buyer Email 3"
                      class="is-small"
                      v-model="formData.buyerEmail3"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="SalePrice"
                      label="Sale Price"
                      type="text"
                      vid="SalePrice"
                      placeholder="Sale Price"
                      spellcheck="true"
                      aria-label="Sale Price"
                      class="is-small"
                      v-model="formData.salePrice"
                      :disabled="true"
                    />
                  </div>
                </div>
                <div class="pt-6">&nbsp;</div>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="ClosingDate"
                      label="Closing Date"
                      type="text"
                      vid="ClosingDate"
                      placeholder="Closing Date"
                      spellcheck="true"
                      aria-label="Closing Date"
                      class="is-small"
                      v-model="formData.closingDate"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-5">
                    <valid-input
                      style="width:50px !important;"
                      name="EscrowLetterDocument"
                      label="Escrow Letter Document"
                      type="text"
                      vid="EscrowLetterDocument"
                      placeholder="Escrow Letter Document"
                      spellcheck="true"
                      aria-label="Escrow Letter Document"
                      class="is-small"
                      v-model="formData.escrowLetterDocument"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-1" style="margin-top: 26px;">
                    <button
                      type="button"
                      @click.prevent="openEscrowLetterDocument()"
                      :disabled="!formData.disclosureLetterDocument"
                      class="button is-link is-light"
                    >
                      View
                    </button>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="DisclosureDueDate"
                      label="Disclosure Due Date"
                      type="text"
                      vid="DisclosureDueDate"
                      placeholder="Disclosure Due Date"
                      spellcheck="true"
                      aria-label="Disclosure Due Date"
                      class="is-small"
                      v-model="formData.disclosureDueDate"
                      :disabled="true"
                    />
                  </div>

                  <div class="column is-5">
                    <valid-input
                      style="width:50px !important;"
                      name="BuyerNotificationLetter"
                      label="Buyer Notification Letter"
                      type="text"
                      vid="BuyerNotificationLetter"
                      placeholder="Buyer Notification Letter"
                      spellcheck="true"
                      aria-label="Buyer Notification Letter"
                      class="is-small"
                      v-model="formData.buyerNotificationLetter"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-1" style="margin-top: 26px;">
                    <button
                      type="button"
                      @click.prevent="openBuyerNotificationLetter()"
                      :disabled="!formData.buyerNotificationLetter"
                      class="button is-link is-light"
                    >
                      View
                    </button>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="DisclosureSentDateComponent"
                      label="Disclosure Sent Date"
                      type="text"
                      vid="DisclosureSentDateComponent"
                      placeholder="Disclosure Sent Date"
                      spellcheck="true"
                      aria-label="Disclosure Sent Date"
                      class="is-small"
                      v-model="formData.disclosureSentDate"
                      :disabled="true"
                    />
                  </div>

                  <div class="column is-5">
                    <valid-input
                      style="width:50px !important;"
                      name="SellerNotificationLetter"
                      label="Seller Notification Letter"
                      type="text"
                      vid="SellerNotificationLetter"
                      placeholder="Seller Notification Letter"
                      spellcheck="true"
                      aria-label="Seller Notification Letter"
                      class="is-small"
                      v-model="formData.sellerNotificationLetter"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-1" style="margin-top: 26px;">
                    <button
                      type="button"
                      @click.prevent="openSellerNotificationLetter()"
                      :disabled="!formData.sellerNotificationLetter"
                      class="button is-link is-light"
                    >
                      View
                    </button>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="CompletedDate"
                      label="Completed Date"
                      type="text"
                      vid="CompletedDate"
                      placeholder="Completed Date"
                      spellcheck="true"
                      aria-label="Completed Date"
                      class="is-small"
                      v-model="formData.completedDate"
                      :disabled="true"
                    />
                  </div>

                  <div class="column is-5">
                    <valid-input
                      style="width:50px !important;"
                      name="DisclosureLetterDocument"
                      label="Disclosure Letter Document"
                      type="text"
                      vid="DisclosureLetterDocument"
                      placeholder="Disclosure Letter Document"
                      spellcheck="true"
                      aria-label="Disclosure Letter Document"
                      class="is-small"
                      v-model="formData.disclosureLetterDocument"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-1" style="margin-top: 26px;">
                    <button
                      type="button"
                      @click.prevent="openDisclosureLetterDocument()"
                      :disabled="!formData.disclosureLetterDocument"
                      class="button is-link is-light"
                    >
                      View
                    </button>
                  </div>
                </div>
                <div class="pt-6">&nbsp;</div>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="TitleCompanyName"
                      label="Title Company Name"
                      type="text"
                      vid="TitleCompanyName"
                      placeholder="Title Company Name"
                      spellcheck="true"
                      aria-label="Title Company Name"
                      class="is-small"
                      v-model="formData.titleCompanyName"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="InspectionRequired"
                      label="Inspection Required"
                      type="text"
                      vid="InspectionRequired"
                      placeholder="Inspection Required"
                      spellcheck="true"
                      aria-label="Inspection Required"
                      class="is-small"
                      v-model="formData.inspectionRequired"
                      :disabled="true"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="TitleCompanyContactName"
                      label="Title Company Contact Name"
                      type="text"
                      vid="TitleCompanyContactName"
                      placeholder="Title Company Contact Name"
                      spellcheck="true"
                      aria-label="Title Company Contact Name"
                      class="is-small"
                      v-model="formData.titleCompanyContactName"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="InspectionDate"
                      label="Inspection Date"
                      type="text"
                      vid="InspectionDate"
                      placeholder="Inspection Date"
                      spellcheck="true"
                      aria-label="Inspection Date"
                      class="is-small"
                      v-model="formData.inspectionDate"
                      :disabled="true"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="TitleCompanyContactEmail"
                      label="Title Company Contact Email"
                      type="text"
                      vid="TitleCompanyContactEmail"
                      placeholder="Title Company Contact Email"
                      spellcheck="true"
                      aria-label="Title Company Contact Email"
                      class="is-small"
                      v-model="formData.titleCompanyContactEmail"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-6">
                    &nbsp;
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'

// components
import ValidInput from '@/components/inputs/ValidInput'

export default {
  name: 'EscrowDetails',
  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    PageContentLayoutOnly
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  created() {},

  mounted() {
    this.reload()
  },

  watch: {
    hoaId() {
      this.reload()
    }
  },

  methods,

  i18n: {
    messages: {
      en: {
        escrowDetail: { title: 'Escrow Details' }
      }
    }
  }
}
</script>
