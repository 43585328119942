import { escrowStore } from '@/services/Escrow/store'
import { parseDate } from '@/utilities/Date/parse'
import parseAddress from '@/utilities/address/parse'
import _get from 'lodash/get'
import { notifyMessage, notifyWarning } from '@/services/notify'
import { base64toBlob, showPdf, showAnyType } from '@/services/blobutility'

var DEFAULT_FORM_VALUES = {
  buyerName: '',
  buyerPhone: '',
  address: '',
  buyerEmail1: '',
  buyerEmail2: '',
  buyerEmail3: '',
  escrowType: '',
  escrowNumber: '',
  salePrice: 0,
  closingDate: null,
  disclosureDueDate: null,
  disclosureSentDate: null,
  completedDate: null,
  escrowLetterDocument: '',
  buyerNotificationLetter: '',
  sellerNotificationLetter: '',
  disclosureLetterDocument: '',
  titleCompanyName: '',
  titleCompanyContactName: '',
  titleCompanyContactEmail: '',
  inspectionRequired: 'false',
  inspectionDate: null
}

export const methods = {
  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async reload() {
    this.loading = true

    const dateFormat = 'MM/DD/YYYY'

    console.debug('route values=' + JSON.stringify(this.$route.params))

    this.escrowID = this.$route.params.id
    this.escrowBuyerName = this.$route.params.name

    console.debug('viewOnly=' + this.viewOnly)

    this.formData = { ...DEFAULT_FORM_VALUES }

    await this.loadEscrowByID()

    if (this.escrowDetail) {
      try {
        this.formData.buyerName = _get(this.escrowDetail, 'buyerName', '')
        this.formData.buyerPhone = _get(this.escrowDetail, 'buyerPhone', '')
        this.formData.buyerEmail1 = _get(this.escrowDetail, 'buyerEmail1', '')
        this.formData.buyerEmail2 = _get(this.escrowDetail, 'buyerEmail2', '')
        this.formData.buyerEmail3 = _get(this.escrowDetail, 'buyerEmail3', '')
        this.formData.escrowType = _get(this.escrowDetail, 'escrowType', '')
        this.formData.escrowNumber = _get(this.escrowDetail, 'escrowNumber', '')
        this.formData.salePrice = _get(this.escrowDetail, 'salePrice', 0)

        let tempInspectionRequired = _get(this.escrowDetail, 'inspectionRequired', false)

        this.formData.inspectionRequired = tempInspectionRequired === true ? 'true' : 'false'

        this.formData.inspectionDate = parseDate(
          _get(this.escrowDetail, 'inspectionDate', null),
          dateFormat
        )

        this.formData.closingDate = parseDate(
          _get(this.escrowDetail, 'closingDate', null),
          dateFormat
        )
        this.formData.disclosureDueDate = parseDate(
          _get(this.escrowDetail, 'disclosureDueDate', null),
          dateFormat
        )
        this.formData.disclosureSentDate = parseDate(
          _get(this.escrowDetail, 'disclosureSentDate', null),
          dateFormat
        )
        this.formData.completedDate = parseDate(
          _get(this.escrowDetail, 'completedDate', null),
          dateFormat
        )
        this.formData.buyerNotificationLetter = _get(
          this.escrowDetail,
          'buyerNotificationLetterDocumentFile',
          ''
        )
        this.formData.sellerNotificationLetter = _get(
          this.escrowDetail,
          'sellerNotificationLetterDocumentFile',
          ''
        )
        this.formData.disclosureLetterDocument = _get(
          this.escrowDetail,
          'disclosureLetterDocumentFile',
          ''
        )
        this.formData.escrowLetterDocument = _get(this.escrowDetail, 'escrowLetterDocumentFile', '')
        this.formData.titleCompanyName = _get(this.escrowDetail, 'titleCompanyName', '')
        this.formData.titleCompanyContactName = _get(
          this.escrowDetail,
          'titleCompanyContactName',
          ''
        )
        this.formData.titleCompanyContactEmail = _get(
          this.escrowDetail,
          'titleCompanyContactEmail',
          ''
        )

        this.formData.address = parseAddress({
          address: _get(this.escrowDetail, 'unit.address', '')
        })
      } catch (e) {
        console.debug('error while loading the escrow detail data:' + e)
      }
    }

    this.loading = false
  },

  async loadEscrowByID() {
    await escrowStore
      .dispatch('getEscrowById', {
        escrowID: this.escrowID
      })
      .then(({ result }) => {
        if (result) {
          this.escrowDetail = result
        }
      })
  },

  async openEscrowLetterDocument() {
    await this.getDocumentFile('EscrowLetterDocument')
  },

  async openBuyerNotificationLetter() {
    await this.getDocumentFile('BuyerNotificationLetterDocument')
  },

  async openSellerNotificationLetter() {
    await this.getDocumentFile('SellerNotificationLetterDocument')
  },

  async openDisclosureLetterDocument() {
    await this.getDocumentFile('DisclosureLetterDocument')
  },

  async getDocumentFile(type) {
    try {
      let contentType = 'application/pdf'

      const params = {
        escrowID: this.escrowID,
        documentType: type,
        asBase64: true,
        asRawBase64: true
      }

      await escrowStore.dispatch('getFileByType', {
        params,
        done: ({ details }) => {
          if (details) {
            if (this.isDebug == true) console.debug('base64pdf........................' + details)

            if (
              type === 'EscrowLetterDocument' ||
              type === 'DisclosureLetterDocument' ||
              type === 'BuyerNotificationLetterDocument' ||
              type === 'SellerNotificationLetterDocument'
            ) {
              const blob = base64toBlob(details, 'text/html')
              showAnyType(blob, 'text/html')
            } else {
              const blob = base64toBlob(details, contentType)
              showPdf(blob)
            }
            notifyMessage(`Successfully downloaded the document.`)
            return
          }
        }
      })
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
      notifyWarning('There was a problem downloading the document.')
    }
  }
}
