import { escrowStore } from '@/services/Escrow/store'
import { notifyError } from '@/services/notify'
import $ from 'jquery'

export const methods = {
  async reload() {},

  open() {
    this.isOpen = true
    this.loading = false
  },

  closeModal() {
    this.$emit('update:toggle', (this.documentToggle = !this.documentToggle))

    // auto-close modal
    $('#clickclose').click()
  },

  async onFormSubmit() {
    if (
      this.escrowID <= 0 ||
      !this.inspectionCompleteDate ||
      this.inspectionCompleteDate === undefined
    ) {
      notifyError(
        'There was a problem processing the inspection close. Please make sure you select a close date.'
      )
      return
    }

    this.loading = true

    try {
      const payload = {
        escrowID: this.escrowID,
        inspectionCompleteDate: this.inspectionCompleteDate
      }

      console.log('payload=' + JSON.stringify(payload))

      await escrowStore.dispatch('inspectionClose', {
        payload,
        done: () => {
          this.closeModal()
        }
      })
    } catch (e) {
      notifyError(e)
    }
    this.loading = false
  }
}
